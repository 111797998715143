// todo add styles

table.mat-mdc-table
  tr.mat-mdc-row.mdc-data-table__row:has(.mat-column-gaxExpandableRowDetail) {
  height: 0;
  & > td.mat-mdc-cell.mdc-data-table__cell {
    padding: 0;
    &.mat-column-gaxExpandableRowDetail {
      height: 0;
      border-bottom-width: 0px;
      .ng-trigger-gaxExpandRow {
        background: #f8f8f8;
        position: relative;
        &:has(table) {
          padding-top: 0 !important;
        }
        table {
          background: #f8f8f8;
        }
      }
    }
  }
}

table {
  td {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  td.mat-mdc-cell a {
    color: #000;
    font-weight: 600;
    text-decoration: underline;
    cursor: pointer;
  }
}

tr.mat-mdc-row.detail-row {
  height: 0;

  & > td.mat-mdc-cell.sub-col {
    height: 0;
    padding: 0;
    border-bottom: 0 none !important;

    .ng-trigger-detailExpand > div {
      position: relative;
      z-index: 1;
      background: #f8f8f8;
      box-shadow:
        inset 0px 4px 5px -4px rgba(0, 0, 0, 0.1),
        inset 0px 8px 10px -8px rgba(0, 0, 0, 0.15),
        inset 0px -4px 5px -4px rgba(0, 0, 0, 0.1),
        inset 0px -8px 10px -8px rgba(0, 0, 0, 0.15);
      padding: 1rem 1.5rem 2rem;
    }
  }

  & > td.mat-mdc-cell.detail-col {
    height: 0;
    padding: 0;
    border-bottom: 0 none !important;

    .ng-trigger-detailExpand > div {
      position: relative;
      z-index: 1;
      background: #f8f8f8;

      table {
        background: #f8f8f8;
      }
    }
  }
}

//Alex told to do
// .mat-table-inner {
//   padding: 1.5rem 0;
//   margin-bottom: 1.5rem;
//   background-color: #f8f8f8;
// }

.mat-table-inner__header-row,
.mat-table-inner__row {
  display: flex;
  margin: 0 1.5rem;

  & > span {
    flex: 0 0 25%;
    white-space: normal;
    padding: 0 1.5rem;

    &:first-of-type {
      padding-left: 0;
    }

    &:last-of-type {
      padding-right: 0;
      margin-bottom: 2rem;
    }
  }
}

.mat-table-inner__header-row > span {
  text-transform: uppercase;
  font-size: 0.75rem;
  font-weight: 800;
  line-height: 1.5;
  letter-spacing: 1.5px;
}

.mat-table-inner__row {
  & > span {
    padding-top: 0.25rem;
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.5;
  }

  &:not(:last-of-type) {
    padding-bottom: 2rem;
    margin-bottom: 2rem;
  }
}
