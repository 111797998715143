//  Support for left and right sticky columns
table.mat-mdc-table {
  // First and Last column - add shadow and make column fit content
  tr > td,
  tr > th {
    &.mat-mdc-table-sticky:first-of-type,
    &.mat-mdc-table-sticky.mat-mdc-table-sticky-border-elem-left {
      // padding-left: 1.5rem;
      white-space: nowrap;
      // box-shadow: 6px 0px 0.5rem rgba(0, 0, 0, 0.05);
    }
  }

  // Add some spacing to account for shadow from 1st column - cleaner look
  tr > th.mat-mdc-header-cell,
  tr > td.mat-mdc-cell {
    &.mat-mdc-table-sticky:first-of-type + th,
    &.mat-mdc-table-sticky:first-of-type + td {
      padding-left: 1rem;
    }
  }
}
