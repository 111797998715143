@use "sass:map";

.gax-inline-form-field-wrapper {
  display: flex;
  margin-bottom: 1.5rem;
  row-gap: 1.5rem;
  flex-flow: wrap;
  align-items: center;
  // When using "&nbsp;" space
  white-space: break-spaces;
  column-gap: 1.75px;

  &.auto-space,
  &.auto-space .new-line {
    // Use column-gap 1rem to maintain space without use of "&nbsp;" space
    column-gap: 1rem;
  }

  .static-text {
    display: inline-block;
    padding: 0 0.75rem;
    align-content: center;
    height: 2rem;
    background-color: #f2f2f2;
    font-weight: 600;
    border-radius: 2px;
  }

  // Applies to basic input, gax-date-picker, and gax-country-select
  mat-form-field {
    .mdc-text-field--filled .mat-mdc-floating-label {
      display: none;
    }
    input {
      background-color: #f2f2f2;
    }
    .mat-mdc-form-field-infix {
      min-width: 80px;
    }
    &:has(mat-select.mat-mdc-select-multiple) .mat-mdc-form-field-infix {
      min-width: 180px;
    }
  }
  // Applies to basic input, gax-country-select, and gax-date-picker #2

  // Placeholder
  mat-form-field.mat-mdc-form-field.mat-mdc-form-field-type-mat-input
    .mat-mdc-input-element:not(:placeholder-shown) {
    font-size: 1rem !important;
    color: black;
    min-height: 2rem;
    font-weight: 600 !important;
  }
  mat-form-field.mat-mdc-form-field.mat-mdc-form-field-type-mat-select:not(
      .mat-mdc-paginator-page-size-select
    )
    mat-select.mat-mdc-select
    span.mat-mdc-select-min-line {
    font-weight: 600 !important;
    color: black;
    font-size: 1rem;
  }

  mat-form-field.mat-mdc-form-field {
    &.mat-mdc-form-field-type-mat-input .mat-mdc-input-element,
    mat-select.mat-mdc-select .mat-mdc-select-placeholder {
      color: #676767;
      font-size: 1rem !important;
      font-weight: 600 !important;
    }
  }

  .mat-mdc-select-placeholder {
    color: #676767 !important;
  }

  mat-select.mat-mdc-select {
    font-size: 1rem !important;
    background-color: #f2f2f2;
    font-weight: 300 !important;
  }

  mat-form-field.mat-mdc-form-field.mat-mdc-form-field-type-mat-select:not(
      .mat-mdc-paginator-page-size-select
    )
    mat-select.mat-mdc-select
    .mat-mdc-select-trigger {
    line-height: 2rem !important;
    min-height: 2rem !important;
  }
  mat-form-field {
    mat-form-field.mat-mdc-form-field.mat-mdc-form-field-type-mat-input:not(
        .datepicker
      )
      .mat-mdc-form-field-icon-prefix,
    mat-form-field.mat-mdc-form-field.mat-mdc-form-field-type-mat-input:not(
        .datepicker
      )
      .mat-mdc-form-field-icon-suffix {
      background-color: #f2f2f2;
    }
  }

  mat-form-field {
    .mat-mdc-icon-button.mat-mdc-button-base {
      padding: 0;
      width: 2rem;
      height: 2rem;
    }
    mat-icon {
      color: black;
      font-size: 1.25rem;
      width: 1.25rem;
      height: 1.15rem;
    }
    div.mat-mdc-form-field-icon-suffix {
      background-color: #f2f2f2;
    }
    mat-date-range-input {
      background-color: #f2f2f2;
      div.mat-date-range-input-container {
        div.mat-date-range-input-wrapper {
          input {
            font-size: 1rem !important;
            color: black;
          }
        }
      }
      span {
        font-size: 1rem !important; // Date Input Separator
        color: black;
      }
    }
    mat-date-picker-toggle {
      button {
        mat-icon {
          color: black;
        }
      }
    }
  }

  // Select
  mat-form-field.mat-mdc-form-field.mat-mdc-form-field-type-mat-select:not(
      .mat-mdc-paginator-page-size-select
    )
    mat-select
    .mat-mdc-select-value {
    font-size: 1rem;
    padding-left: 0.75rem;
    line-height: unset;

    mat-select-trigger {
      font-size: 1rem !important;
      font-weight: 600;
      padding: 0;
      letter-spacing: var(
        --mat-form-field-container-text-tracking,
        var(--mat-app-body-large-tracking)
      ) !important;
      color: black;
    }
  }

  .mat-mdc-text-field-wrapper.mdc-text-field.mdc-text-field--filled {
    border-radius: 2px !important;
  }

  // mat-select padding
  mat-form-field.mat-mdc-form-field.mat-mdc-form-field-type-mat-select:not(
      .mat-mdc-paginator-page-size-select
    )
    .mat-mdc-form-field-infix,
  mat-form-field.mat-mdc-form-field.mat-mdc-form-field-type-mat-select:not(
      .mat-mdc-paginator-page-size-select
    ).mat-mdc-focused
    .mat-mdc-form-field-infix {
    padding: 0;
    min-height: auto !important;
    width: auto;
  }

  // mat-select hint
  mat-form-field.mat-mdc-form-field.mat-mdc-form-field-type-mat-select:not(
      .mat-mdc-paginator-page-size-select
    )
    .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }

  // mat-select underline
  .mdc-line-ripple {
    display: none !important;
  }

  // Select arrow
  mat-form-field.mat-mdc-form-field.mat-mdc-form-field-type-mat-select:not(
      .mat-mdc-paginator-page-size-select
    )
    mat-select.mat-mdc-select
    .mat-mdc-select-arrow-wrapper
    .mat-mdc-select-arrow:after {
    content: "";
    background: url("data:image/svg+xml,%3Csvg width='18' height='9' viewBox='0 0 18 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16.7275 0.364258L8.86328 8.22852L0.999024 0.364258' stroke='black' stroke-width='1.1' /%3E%3C/svg%3E");
    width: 1.125rem;
    height: 0.55rem;
    display: block;
    position: relative;
    right: 0.5rem;
    bottom: calc(50% - 0.4rem);
    transform: scale(0.9);
  }

  // input
  mat-form-field.mat-mdc-form-field.mat-mdc-form-field-type-mat-input
    .mat-mdc-form-field-infix,
  mat-form-field.mat-mdc-form-field.mat-mdc-form-field-type-mat-input.mat-mdc-focused
    .mat-mdc-form-field-infix {
    padding: 0 0 0 0.75rem !important;
    min-height: auto !important;
    width: auto;
    background: #f2f2f2;

    display: flex;
  }

  mat-form-field.mat-mdc-form-field.mat-mdc-form-field-type-mat-input
    .mat-mdc-input-element {
    min-height: 2rem;
  }

  // country select
  gax-country-select {
    mat-form-field {
      min-width: 12rem;
    }

    mat-form-field.mat-mdc-form-field.mat-mdc-form-field-type-mat-input
      .mat-mdc-input-element {
      min-height: 2rem;
    }

    .display-input {
      font-size: 1rem;
      font-weight: 600;
      vertical-align: top;
      line-height: 2rem;
      vertical-align: bottom;

      width: 100%;
      text-overflow: unset;
    }

    .icons {
      position: relative;
    }

    mat-icon.mdc-icon-button[matsuffix] {
      color: black;
      font-size: 20px;
    }
  }

  .mat-mdc-form-field-subscript-wrapper.mat-mdc-form-field-bottom-align {
    display: none;
  }

  // action button
  button.mdc-button.mat-mdc-button-base.mat-text,
  button.mdc-button.mat-mdc-button-base.mat-action,
  a.mdc-button.mat-mdc-button-base.mat-text,
  a.mdc-button.mat-mdc-button-base.mat-action {
    gap: 0;
  }

  // gax-select-form-field-deprecated-use-mat-select
  gax-select-form-field-deprecated-use-mat-select {
    padding-bottom: 0;
    margin-bottom: 0;
    min-height: auto;
    min-width: 80px;

    &[multiple] {
      min-width: 180px;
    }

    &.active .control {
      border-bottom: none !important;
    }

    &:focus-visible .control {
      border-bottom: none !important;
    }

    .control {
      height: 2rem;
      padding: 0;
      border: none;
      border-bottom: none !important;
      margin: 0;
      border-radius: 2px;
      background-color: #f2f2f2;
      &:hover {
        cursor: pointer;
        border-bottom: none;
      }
      .suffix {
        padding-right: 0.5rem;
      }
    }

    .gax-label {
      margin-bottom: 0;
    }

    .control > div {
      padding-left: 0.75rem;
    }

    .selected-value,
    .select-trigger {
      font-size: 1rem !important;
      font-weight: 600;
      padding: 0;
      letter-spacing: var(
        --mat-form-field-container-text-tracking,
        var(--mat-app-body-large-tracking)
      ) !important;
    }

    .caret-wrapper .drop-down-caret {
      transform: rotateZ(45deg) scale(0.925);
    }

    .gax-label,
    .hint,
    .error {
      display: none;
    }
  }

  .new-line {
    width: 100%;
    display: flex;
    align-items: center;
    row-gap: 1rem;
    flex-flow: wrap;
  }

  .clear-button {
    text-decoration: underline;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
  }

  // datepicker
  mat-datepicker-toggle .mat-mdc-icon-button svg {
    vertical-align: middle !important;
  }

  // datepicker range
  mat-form-field.mat-mdc-form-field.datePickerMinWidth.mat-mdc-form-field-type-mat-date-range-input.mat-mdc-form-field-label-always-float.mat-mdc-form-field-has-icon-suffix.mat-form-field-appearance-fill.mat-primary {
    min-width: 18rem;
  }

  .mat-mdc-form-field-flex {
    height: 2rem;
  }

  .mat-mdc-form-field-type-mat-date-range-input
    .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined)
    .mat-mdc-form-field-infix {
    padding-top: 0;
    padding-bottom: 0;
    min-height: 0;
  }

  mat-date-range-input {
    padding-left: 0.75rem;
  }

  .mat-mdc-form-field-type-mat-date-range-input
    .mat-date-range-input-container {
    min-height: 2.1rem;
  }

  .mat-date-range-input-wrapper {
    min-height: 2.1rem;
  }

  mat-form-field
    mat-date-range-input
    div.mat-date-range-input-container
    div.mat-date-range-input-wrapper
    input {
    font-weight: 600;
    height: 2rem;
    caret-color: transparent;
  }

  .gax-inline-form-field-wrapper mat-form-field mat-date-range-input span {
    height: 2rem;
    align-content: center;
  }

  .mat-date-range-input-end-wrapper {
    margin-left: 0.25rem;
  }

  mat-form-field mat-date-range-input span {
    height: 1.6rem;
    font-weight: 600;
  }

  mat-form-field.mat-mdc-form-field.mat-mdc-form-field-type-mat-input.mat-datepicker-input
    .mat-mdc-input-element:not(:placeholder-shown) {
    min-height: 2.1rem;
  }

  .mat-mdc-form-field-type-mat-date-range-input .mat-mdc-form-field-icon-prefix,
  .mat-mdc-form-field-type-mat-date-range-input
    .mat-mdc-form-field-icon-suffix {
    align-items: baseline;
  }

  .mat-form-field-invalid mat-datepicker-toggle.mat-datepicker-toggle::before {
    align-self: center;
  }

  mat-form-field.mat-mdc-form-field.mat-mdc-form-field-type-mat-input.mat-form-field-invalid:not(
      .datepicker
    )
    .mat-mdc-form-field-flex::after {
    bottom: 0.4rem;
  }

  mat-form-field.mat-mdc-form-field.mat-mdc-form-field-type-mat-input.mat-form-field-invalid:not(
      .datepicker
    )
    .mat-mdc-form-field-flex:has(.mat-mdc-form-field-icon-suffix)::after {
    right: 2.138rem !important;
    bottom: 0.4rem !important;
  }
}
