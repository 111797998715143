// Mat-select class "mat-select--wrap-selected-option" - to add text wrap to selected option
mat-form-field.mat-mdc-form-field.mat-mdc-form-field-type-mat-select:not(
    .mat-paginator-page-size-select
  ) {
  mat-select.mat-mdc-select.mat-select--wrap-selected-option {
    .mat-mdc-select-value {
      // width: 90%;
      letter-spacing: 0.05rem;
      min-height: 31px;
      line-height: 31px;

      .mat-mdc-select-value-text {
        white-space: break-spaces;
        overflow: visible;
        text-overflow: initial;
      }
    }
  }
}
