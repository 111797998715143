@use "sass:map";
@use "../../../utils/variables";
@use "../../../utils/palette";

mat-chip-listbox,
mat-chip-grid,
mat-chip-set {
  .mat-chip-listbox-wrapper {
    margin-top: 0;
    // margin-bottom: 0.25rem;
  }

  //bg color to match GA typography
  .mat-chip.mat-standard-chip {
    background-color: map.get(palette.$grey-palette, 100);
  }

  //cross icon format to match GA typography
  .mat-chip.mat-standard-chip .mat-chip-remove {
    color: map.get(palette.$grey-palette, 300);
    opacity: 1;
  }

  //alignment to match GA typography
  .mdc-evolution-chip__icon--trailing {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 4px 4px 4px 12px;
    font-size: 20px !important;
  }

  //icon format
  .mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled)
    .mdc-evolution-chip__icon--trailing {
    color: map.get(palette.$grey-palette, 400);
    opacity: 1;
  }

  //label to match GA typography
  .mat-chip {
    font-weight: 600;
    font-size: 1rem;
    line-height: 150%;
  }

  span.mdc-evolution-chip__text-label.mat-mdc-chip-action-label {
    font-weight: 300;
    font-size: 1rem;
  }

  .mat-mdc-standard-chip .mdc-evolution-chip__cell--primary {
    width: 100%;
    //padding: 4px;
    padding-right: 8px;
    align-items: flex-start;
  }

  .mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) {
    background-color: map.get(palette.$grey-palette, 100);
  }

  // filter label style used for table advanced filter chips
  .mat-chip-filter-label {
    text-transform: uppercase;
    font-weight: 800;
    color: map.get(palette.$grey-palette, 400);
    font-size: 12px;
    padding-right: 4px;
    letter-spacing: 0.09375rem;
  }

  .mat-chip-filter-option {
    font-weight: 300;
    color: palette.$black;
    font-size: 16px;
    //padding-right: 6px;
    letter-spacing: 1.5px;
  }

  // Selected background color
  .mat-mdc-standard-chip.mat-primary.mat-mdc-chip-selected,
  .mat-mdc-standard-chip.mat-primary.mat-mdc-chip-highlighted {
    --mdc-chip-elevated-container-color: map.get(palette.$grey-palette, 200);
  }

  //Hover
  .mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled):hover {
    background-color: variables.$grey-10;
  }

  //label
  .mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled)
    .mdc-evolution-chip__text-label {
    color: palette.$black;
    text-align: center;
  }

  //tab focus chip
  .mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled):focus-visible {
    background-color: map.get(palette.$grey-palette, 100);
    outline: 0;
  }

  //tab focus close icon format with rounded border
  .mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled):focus-visible {
    .mdc-evolution-chip__cell--trailing {
      padding-left: 8px;
      padding-right: 8px;
    }

    .mdc-evolution-chip__icon--trailing {
      box-shadow: 0px 0px 0px 0.15em variables.$black;
      border-radius: 10px;
      padding: 0px;
      height: 16px;
      width: 16px;
      font-size: 20px !important;
    }
  }

  //disabled chip bg color
  .mat-mdc-standard-chip.mdc-evolution-chip--disabled {
    background-color: variables.$grey-3;
  }

  .mat-mdc-standard-chip.mdc-evolution-chip--disabled
    .mdc-evolution-chip__text-label {
    color: variables.$grey-25;
  }

  //disable cdk focused
  .mat-mdc-chip-focus-overlay {
    display: none;
  }

  //disable ripple
  .mat-mdc-chip-ripple {
    display: none;
  }

  //disable for category label
  .mat-mdc-standard-chip.mdc-evolution-chip--disabled
    .mdc-evolution-chip__text-label
    > .mat-chip-filter-label,
  .mat-mdc-standard-chip.mdc-evolution-chip--disabled
    .mdc-evolution-chip__text-label
    > .mat-chip-filter-option {
    color: variables.$grey-25;
  }

  //remove default padding of label and icon
  .mat-mdc-standard-chip.mdc-evolution-chip--with-primary-graphic.mdc-evolution-chip--with-trailing-action
    .mdc-evolution-chip__graphic,
  .mat-mdc-standard-chip.mdc-evolution-chip--with-primary-graphic.mdc-evolution-chip--with-trailing-action
    .mdc-evolution-chip__action--trailing,
  .mat-mdc-standard-chip.mdc-evolution-chip--with-trailing-action
    .mdc-evolution-chip__action--trailing,
  .mat-mdc-standard-chip.mdc-evolution-chip--with-trailing-action
    .mdc-evolution-chip__action--primary {
    padding: 0px;
  }

  //padding for chip
  .mat-mdc-chip {
    display: inline-flex;
    padding: 0.25rem 0.5rem 0.25rem 0.75rem;
    justify-content: center;
    align-items: center;
  }

  //disabled icon color
  .mat-mdc-standard-chip.mdc-evolution-chip--disabled
    .mdc-evolution-chip__icon--trailing {
    color: variables.$grey-25;
    opacity: 1;
  }
}
