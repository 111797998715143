@use "sass:map";
@use "../../../utils/variables";
@use "../../../utils/palette";

//  Styles for
table.mat-mdc-table {
  tr.mat-mdc-row > td.mat-mdc-cell {
    height: 56px;

    mat-icon.mat-icon {
      color: variables.$grey-50;
      // cursor: pointer;
    }

    & .mat-mdc-menu-trigger.selected[aria-expanded="true"] mat-icon.mat-icon {
      color: map.get(palette.$green-palette, 900);

      &:hover {
        color: variables.$black;
      }
    }

    &:not(:first-child):has(mat-select) {
      margin-right: 2rem;
    }
  }
  // Material table menu dropdown panel, used for more options "..." button, typically used on the last column
  .mat-mdc-menu-panel {
    min-width: 0;
    min-height: 0;

    & > .mat-mdc-menu-content {
      padding: 0;
    }
  }
}
