div.mat-autocomplete-panel.multiselect-autocomplete {
  padding: 0;

  mat-option {
    padding: 0;

    .mat-option-text {
      height: 3.5rem;
      line-height: 3.5rem;
      margin: 0;

      .multiselect-autocomplete-option-overlay {
        padding: 0 2rem;
      }
    }
  }
}

.autocomplete-caret {
  .mat-mdc-form-field-flex {
    .mat-mdc-form-field-infix {
      margin-right: 1rem;
    }

    &::before {
      content: "";
      background: url("data:image/svg+xml,%3Csvg width='18' height='9' viewBox='0 0 18 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16.7275 0.364258L8.86328 8.22852L0.999024 0.364258' stroke='black' stroke-width='1.1' /%3E%3C/svg%3E");
      width: 1.125rem;
      height: 1.5rem;
      background-repeat: no-repeat;
      display: block;
      position: absolute;
      bottom: 0;
      align-self: end;
      right: 0;
      transform: translateX(-0.5rem);
    }
  }

  &.mat-form-field-invalid {
    .mat-mdc-form-field-flex {
      .mat-mdc-form-field-infix {
        margin-right: 3rem;
      }

      &::after {
        transform: translateX(-2rem);
        transition: transform 0.5s ease;
      }

      .mat-mdc-form-field-suffix {
        display: flex;
        align-items: center;

        .mat-mdc-form-field-suffix::before {
          content: "";
          width: 20px;
          height: 21px;
          background: url("data:image/svg+xml,%3Csvg width='20' height='21' viewBox='0 0 20 21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10 0.5C4.48 0.5 0 4.98 0 10.5C0 16.02 4.48 20.5 10 20.5C15.52 20.5 20 16.02 20 10.5C20 4.98 15.52 0.5 10 0.5ZM11 15.5H9V13.5H11V15.5ZM11 11.5H9V5.5H11V11.5Z' fill='%23E12621'/%3E%3C/svg%3E");
          display: block;
          position: relative;
          margin-right: 0.25rem;
          margin-left: 0.25rem; // Add margin-left to create space between the error icon and the select arrow
          bottom: calc(50% - 0.6rem);
          border-radius: 50%;
          animation: invalid_error_icon_animation 1s linear;
        }
      }
    }
  }
}

// Animation for when error occurs
@keyframes invalid_error_icon_animation {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-2rem);
  }
}
