.mat-mdc-button,
.mat-mdc-icon-button {
  &:not(.pending) {
    gax-button-spinner {
      display: none;
    }
  }

  &.pending .mdc-button__label {
    visibility: hidden;

    gax-button-spinner {
      visibility: visible;
    }
  }
}
