.form-field-wrapper mat-label {
  position: absolute;
  font-size: 1rem;
  font-weight: 300;
  display: flex;
  align-items: center;
  height: 4rem;
  top: -1rem;
}

// Required mark
span.mat-placeholder-required {
  font-size: 1rem;
}

// Required mark focused
.mat-focused .mat-form-field-required-marker {
  color: inherit;
}

mat-label {
  // display: block; // Need to be more specific here - ruins required fields
}
