@use "../utils/variables";

// Legacy page styles -----------------------------------------------------------------------------------------------
.page-header {
  font-weight: 300;
  margin-bottom: 2rem;

  h1 {
    margin: 1rem 0;
    font-weight: 800;
  }

  p {
    line-height: 1.5;
    max-width: 640px;
  }
}

.page-row-top {
  padding: 1.5rem;
  border-bottom: 1px solid variables.$grey-10;
  font-weight: 800;

  h2 {
    font-size: 1.5rem;
    line-height: 1.25;
    font-weight: 800;
    letter-spacing: 0.15px;
  }

  p {
    font-size: 1rem;
    line-height: 1.5;
    font-weight: 300;
    margin-top: 0.5rem;
  }
}

section {
  form {
    padding: 3.75rem 10.5rem;
    font-weight: 300;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 2rem;
  }
}

.bottom-bar {
  position: sticky;
  bottom: 0;
  width: 100%;
  background-color: variables.$white;
  display: flex;
  justify-content: flex-end;
  padding: 1.25rem 3rem;
  gap: 1rem;
  z-index: 1000;
  border-top: 1px solid variables.$grey-10;
}

.breadcrumbs {
  font-weight: 300;
  margin: 2rem 0px 1rem 0px;

  span,
  strong {
    margin: 0 0.5rem;
  }

  span:first-of-type {
    margin-left: 0;
  }
}

.ga-card-nav .mat-mdc-tab-link-container {
  border: none !important;

  .mat-mdc-tab-list {
    .mat-mdc-tab-link {
      min-height: 100%;
    }

    display: flex;
  }
}

section.page-content-wrapper {
  background: white;
  margin-bottom: 2rem;
}

.page-content {
  width: 848px;
}

// Move to separate
.ga-card {
  width: 100%;
  background-color: white;
  border-radius: 0.188rem;
  //overflow: hidden;
  margin-bottom: 1rem;

  font-size: 1rem;
  font-weight: 300;

  .ga-card-head {
    padding-top: 1rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;

    border-bottom: 1px solid #e6e6e6;

    .ga-card-header {
      display: flex;
      justify-content: space-between;
      align-items: baseline;

      & > button.mat-button {
        transform: translateY(-0.25rem);
      }
    }

    h2,
    .ga-card-title {
      font-size: 1.5rem;
      font-weight: 800;
      margin: 0;
      margin-bottom: 1rem;
    }

    p,
    .ga-card-description {
      font-size: 1rem;
      font-weight: 300;
      margin: 0;
      margin-bottom: 1.5rem;
    }
  }

  .ga-card-body {
    padding: 0 1.5rem 1.5rem;

    & > form {
      padding: 0;
      font-weight: 300;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 2rem;
    }
  }

  .ga-card-nav {
    background-color: white;
    height: 56px;
    width: 100%;
    border-bottom: 1px solid #e6e6e6;

    display: flex;
    justify-content: space-between;

    &.ga-card-nav-left,
    .ga-card-nav-left {
      display: flex;
      justify-content: flex-start;
      flex: 1;

      & > .ga-card-nav-icon,
      & > .ga-card-nav-search-bar {
        border-right: 1px solid #e6e6e6;
      }
    }

    &.ga-card-nav-right,
    .ga-card-nav-right {
      display: flex;
      justify-content: flex-end;
      flex: 1;

      & > .ga-card-nav-icon,
      & > .ga-card-nav-search-bar {
        border-left: 1px solid #e6e6e6;
      }
    }

    & > .ga-card-nav-icon {
      cursor: pointer;
      width: 56px;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      & > mat-icon.mat-icon.color-grey {
        color: #8b8b8b;
      }
    }

    & .ga-card-nav-search-bar {
      display: flex;
      align-items: center;
      padding-left: 1.25rem;
      padding-right: 1.25rem;

      input {
        all: unset;
        font-size: 1rem;
        min-width: 17.5rem;
      }

      & > .mat-icon {
        margin-right: 1rem;
      }

      & > input.mat-input-element {
        min-width: 17.5rem;
      }
    }
  }
}

// TODO: layout Temp, remove instances in GAIN first before removing
.bottom-bar {
  position: sticky;
  bottom: 0;
  width: 100%;
  background-color: white;
  display: flex;
  justify-content: flex-end;
  padding: 1.25rem 3rem;
  gap: 1rem;
  z-index: 1000;
  border-top: 1px solid #e6e6e6;
}

// end of legacy page styles -----------------------------------------------------------------------------------------------

// TODO: File upload -----------------------------------------------------------------------------------------------
.file-upload {
  padding: 1.5rem;
  margin-bottom: 3.5rem;
  border: 1px dashed variables.$grey-25;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  cursor: pointer;
  transition: background-color 0.5s ease;

  &:hover {
    transition: background-color 0.5s ease;
    background-color: hsla(93, 73%, 55%, 0.11);
  }
}

.file-upload-icon {
  margin-bottom: 0.625rem;
  border: 2px solid variables.$grey-75;
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;

  mat-icon.mat-icon {
    color: variables.$grey-75;
    height: 2.25rem;
    width: 2.25rem;
    font-size: 2.25rem;
  }
}

// End of file upload

// TODO: Side nav -----------------------------------------------------------------------------------------------
.side-nav-items {
  display: flex;
  flex-direction: column;
  background: variables.$white;
  padding: 0px;
  margin: 0px;
  font-weight: 300;
  width: 285px;
  border-radius: 0px;
}

li.mat-tree-node {
  cursor: pointer;
}

mat-tree-node.mat-tree-node {
  flex: 0;
  height: auto;
  min-height: 56px;
}

.label {
  color: black;
  text-decoration: none;
  display: flex;
  align-items: center;
  font-size: 1rem;
  width: 100%;
  flex: 1;
  height: 3.625rem;
  font-weight: 300;
  justify-content: space-between;
  flex-direction: row-reverse;
  padding-left: 1.125rem;
  cursor: pointer;
  border-bottom: 1px solid variables.$grey-10;
  background-color: inherit;
}

.completed {
  color: variables.$green-dark;
}

.incomplete {
  color: variables.$red;
}

.info {
  color: variables.$grey-25;
}

.expanded-node {
  background-color: grey;
}

.highlight {
  font-weight: 700;
  background-color: grey;
}

mat-sidenav.mat-sidenav {
  .side-nav-items::-webkit-scrollbar {
    display: none;
  }

  .back-link {
    display: flex;
    align-items: center;
    background-color: variables.$grey-3;
    border-bottom: 1px solid variables.$grey-10;

    a {
      display: inline-flex;
      flex: 1;
      padding: 0.75rem 1rem;
      text-decoration: none;
      color: variables.$black;
      font-size: 1rem;
      line-height: 1.5;
      font-weight: 600;
      letter-spacing: 0.012rem;
    }

    .mat-icon {
      margin-right: 0.5rem;
    }
  }

  // Nav-items
  mat-tree.mat-tree {
    padding-bottom: 3rem;

    // mat-tree-node.mat-tree-node:not(.mat-tree-node-label),
    // mat-nested-tree-node.mat-nested-tree-node,
    mat-tree-node.mat-tree-node[aria-level="2"],
    mat-tree-node.mat-tree-node[aria-level="3"],
    mat-tree-node.mat-tree-node[aria-level="4"],
    mat-tree-node.mat-tree-node[aria-level="5"],
    mat-tree-node.mat-tree-node[aria-level="6"] {
      background-color: variables.$white-20;
    }

    .node-collapsed {
      display: none;
    }

    mat-tree-node.mat-tree-node {
      border-bottom: 1px solid variables.$grey-10;

      // &[aria-level="2"] a {
      //   padding-left: 2.5rem;
      // }

      &[aria-level="3"] a {
        padding-left: 3.125rem;
      }

      // &[aria-level="4"] a {
      //   padding-left: 3.75rem;
      // }

      // &[aria-level="5"] a {
      //   padding-left: 5rem;
      // }

      // &[aria-level="6"] a {
      //   padding-left: 6.25rem;
      // }

      a,
      &.mat-tree-node-label {
        display: flex;
        flex: 1;
        align-items: center;
        color: black;
        text-decoration: none;
        font-size: 1rem;
        width: 100%;
        padding: 1rem 0.5rem 1rem 1.125rem;
        font-weight: 300;
      }

      a.active {
        border-right: 4px solid variables.$green;
        font-weight: 700;
      }

      a.disabled {
        pointer-events: none;
        color: variables.$grey-50;
      }

      &.mat-tree-node-label {
        font-size: 0.75rem;
        line-height: 1.5;
        font-weight: 800;
        letter-spacing: 1.5px;
        text-transform: uppercase;
        padding-top: 1.625rem;
        padding-bottom: 0.75rem;
      }
    }

    gax-icon {
      transition: transform 0.3s ease-out;
      transform: rotate(180deg);

      &.expanded {
        transform: rotate(0deg);
        transition: transform 0.3s ease-out;
      }
    }
  }
}

mat-sidenav-container.mat-drawer-container {
  flex: 1;
}
// end of side nav -----------------------------------------------------------------------------------------------

// TODO: Top nav -----------------------------------------------------------------------------------------------
.top-nav {
  flex: 0;
  display: flex;
  flex-direction: row;
  background-color: variables.$white;
  justify-content: space-between;
  align-items: center;
  padding-left: 1rem;
  border-bottom: 1px solid variables.$grey-10;
}

nav.mat-tab-nav-bar.mat-tab-header.mat-primary {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);

  a.mat-tab-link {
    color: black;
    font-size: 14px;
  }

  .mat-ink-bar {
    background-color: #8fdc4f;
  }
}
// ---------------------------------------------->
