@use "sass:map";
@use "../../../utils/variables";

// Less emphasis
$start-edit-color: variables.$green;
$edit-color: variables.$green-wash;

// More emphasis
// $start-edit-color: variables.$green-dark;
// $edit-color: variables.$green-light;

.mat-mdc-table {
  tr {
    &.edited-row {
      background-color: $edit-color;
    }

    &.saved.previously-edited {
      background-color: $edit-color;
      animation: fadeOut 1s forwards ease-out 0s;
    }

    &:not(.saved).edited {
      background-color: $start-edit-color;
      animation: markAsEdited 1s forwards ease-out 0s;
    }

    &:not(.mat-mdc-header-row):hover {
      background-color: variables.$grey-3 !important;
    }
  }
}

// fade out edited row
.mat-mdc-table {
  tr.edited-row-fade {
    background-color: $edit-color;
    animation: fadeOut 0.75s forwards ease-out 2s;
  }
}

// keyframes for fade out edited row

@keyframes fadeOut {
  from {
    background-color: $edit-color;
  }
  to {
    background-color: variables.$white;
  }
}

@keyframes markAsEdited {
  from {
    background-color: $start-edit-color;
  }
  to {
    background-color: $edit-color;
  }
}
