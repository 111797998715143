// Mat selet dropdown panel styling - positioning and font styles
.cdk-overlay-pane > .mat-select-panel-wrap {
  .mat-select-panel--wrap-options.ng-trigger-transformPanel.mat-select-panel.mat-primary.ng-trigger[role="listbox"] {
    // positioning of panel
    transform: scale(1) translateX(1rem) translateY(3rem) !important;
    min-width: 150% !important;
    border-radius: 0px !important;

    // option font weight
    .mat-option-text {
      font-weight: 500;
    }

    // option styling - adjust padding, height and apply text wrapping
    & > mat-option.mat-option {
      line-height: normal;
      height: auto;
      padding: 1rem;
      white-space: pre-wrap;
    }
  }
}

.cdk-overlay-pane > .mat-select-panel--nowrap {
  & > mat-option .mdc-list-item__primary-text {
    white-space: nowrap;
  }
}
