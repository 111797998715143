/* total width */
::-webkit-scrollbar {
  background-color: #fff;
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-corner {
  background-color: transparent;
}

/* background of the scrollbar except button or resizer */
::-webkit-scrollbar-track {
  background-color: #e6e6e6;
}

/* scrollbar itself */
::-webkit-scrollbar-thumb {
  background-color: #8b8b8b;
  // border-radius: 8px;
  border: 0px solid #f8f8f8;
}

::-webkit-scrollbar-thumb:hover {
  background-color: hsl(0, 0%, 34%);
  border: 0px solid #f8f8f8;
}

/* set button(top and bottom of the scrollbar) */
::-webkit-scrollbar-button {
  display: none;
}

// SideNav overflow for scrollbars
mat-sidenav.mat-sidenav > div.mat-drawer-inner-container {
  scrollbar-width: none;
  overflow: unset;
  overflow-y: auto;
}

// Firefox
* {
  scrollbar-width: thin;
  scrollbar-color: #8b8b8b;
}

// Safari - keep native scrollbar
@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) and (stroke-color: transparent) {
    mat-sidenav.mat-sidenav .side-nav-items::-webkit-scrollbar {
      display: unset;
    }
  }
}
