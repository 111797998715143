@use "projects/gain-lib/assets/sass/utils/variables";
@forward "projects/gain-lib/assets/sass/layout/layout_components"; // Needed for the scrollbar styles to take effect
@import url("https://fonts.googleapis.com/css2?family=Material+Symbols+Rounded");

html {
  background-color: variables.$grey-3;
  min-width: 1200px;
  // TODO: TEMP fix for tab nav bar indicator (needs testing before removing)
  --mdc-tab-indicator-active-indicator-height: 3px;
  --mat-expansion-container-shape: 0;
}

// IMPORTANT: DO NOT ADD MORE STYLES TO THIS FILE.
// New styles should be added to the appropriate file in the gain-lib project.
// If unsure, please ask - Alex Roman/Craig Smitham.

//  ____                __  __          __        ______                        __          __    __
// /\  _`\             /\ \/\ \        /\ \__    /\__  _\                      /\ \        /\ \__/\ \      __
// \ \ \/\ \    ___    \ \ `\\ \    ___\ \ ,_\   \/_/\ \/   ___   __  __    ___\ \ \___    \ \ ,_\ \ \___ /\_\    ____
//  \ \ \ \ \  / __`\   \ \ , ` \  / __`\ \ \/      \ \ \  / __`\/\ \/\ \  /'___\ \  _ `\   \ \ \/\ \  _ `\/\ \  /',__\
//   \ \ \_\ \/\ \L\ \   \ \ \`\ \/\ \L\ \ \ \_      \ \ \/\ \L\ \ \ \_\ \/\ \__/\ \ \ \ \   \ \ \_\ \ \ \ \ \ \/\__, `\
//    \ \____/\ \____/    \ \_\ \_\ \____/\ \__\      \ \_\ \____/\ \____/\ \____\\ \_\ \_\   \ \__\\ \_\ \_\ \_\/\____/
//     \/___/  \/___/      \/_/\/_/\/___/  \/__/       \/_/\/___/  \/___/  \/____/ \/_/\/_/    \/__/ \/_/\/_/\/_/\/___/
