.modal-info-title {
  margin-bottom: 1.5rem;
  font-weight: 800;
  max-width: 90%;
}

.modal-info-description {
  line-height: 150%;
  font-weight: 300;
  font-size: 1rem;
}

.modal-info-link {
  text-decoration: underline;
  font-weight: 700;
  cursor: pointer;
}
