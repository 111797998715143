table.mat-mdc-table tr.mat-mdc-row > td.mat-mdc-cell,
table.mat-mdc-table tr.mat-mdc-footer-row > td.mat-mdc-footer-cell {
  // These additional styles are for select components within a table to match GA Design System
  // This could probably go inside the mat-select.scss - but still needs this specificity to avoid conflicts in other components
  mat-form-field.mat-mdc-form-field.mat-mdc-form-field-type-mat-select:not(
      .mat-mdc-paginator-page-size-select
    )
    .mat-mdc-form-field-infix,
  mat-form-field.mat-mdc-form-field.mat-mdc-form-field-type-mat-select:not(
      .mat-mdc-paginator-page-size-select
    ).mat-mdc-focused
    .mat-mdc-form-field-infix {
    padding: 0;
  }
  mat-select.mat-mdc-select {
    .mat-mdc-select-trigger {
      .mat-mdc-select-arrow-wrapper {
        width: 48px;
        justify-content: center;
        align-items: normal;

        & > .mat-mdc-select-arrow {
          display: none;
        }

        &::after {
          content: "";
          display: block;
          width: 13px;
          margin: 0 1rem;
          height: 8px;
          transform: translateY(0.3rem);
          background: url("data:image/svg+xml,%3Csvg width='13' height='8' viewBox='0 0 13 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.9889 0.656854L6.33203 6.31371L0.675177 0.656854' stroke='black'/%3E%3C/svg%3E%0A");
        }
      }

      .mat-mdc-select-value {
        font-weight: 300;

        .mat-mdc-select-min-line {
          font-size: 1rem;
          font-weight: 300;
        }
      }
    }
  }
  .mdc-text-field--filled:not(.mdc-text-field--disabled)
    .mdc-line-ripple::before {
    border: none;
  }
  mat-form-field.mat-mdc-form-field.mat-mdc-form-field-type-mat-select:not(
      .mat-mdc-paginator-page-size-select
    )
    .mdc-text-field--filled
    .mdc-line-ripple::after {
    border: none;
  }
}

.mat-mdc-table-select-dropdown {
  width: 17rem;

  mat-option.mat-mdc-option {
    font-size: inherit;
    line-height: normal;
    height: auto;
    padding: 1rem;
    white-space: pre-wrap;
  }
}
