@use "sass:map";
@use "../../../utils/variables";
@use "../../../utils/palette";

//Below CSS to match GA typography
mat-tab-group.mat-mdc-tab-group,
nav.mat-mdc-tab-nav-bar {
  //Tab label and nav bar label
  .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab__text-label,
  .mdc-tab--active .mdc-tab__content .mdc-tab__text-label,
  .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab__text-label {
    font-size: 1rem;
    font-weight: 300;
    color: palette.$black !important;
    letter-spacing: 0;
  }

  //Active tab label
  .mdc-tab--active .mdc-tab__content .mdc-tab__text-label {
    font-weight: 600;
  }

  //Active tab border
  .mdc-tab--active .mdc-tab-indicator,
  .mdc-tab-indicator__content--underline {
    border-bottom: 1px solid map.get(palette.$green-palette, 400);
  }

  .mat-mdc-tab-list {
    flex-grow: 0;
  }

  /*.mat-mdc-tab-link-container {
    border: 1px solid #e6e6e6;
  }*/
  //selected tab border color
  .mat-mdc-tab:not(.mat-mdc-tab-disabled)
    .mdc-tab-indicator__content--underline,
  .mat-mdc-tab-link:not(.mat-mdc-tab-disabled)
    .mdc-tab-indicator__content--underline {
    border-color: map.get(palette.$green-palette, 400);
  }

  // tab hover and nav bar hover
  .mat-mdc-tab:not(.mat-mdc-tab-disabled):hover,
  .mat-mdc-tab-link:not(.mat-mdc-tab-disabled):hover {
    background-color: map.get(palette.$grey-palette, 200);
  }

  //disable ripple
  .mat-mdc-tab-ripple,
  .mdc-tab__ripple {
    display: none;
  }

  //tab focus
  .mat-mdc-tab:focus-visible,
  .mat-mdc-tab-link:focus-visible {
    //background-color: map.get(palette.$grey-palette, 200);
    border: 2px solid variables.$black;
    background: variables.$white;
  }

  //disabled hover
  .mat-mdc-tab-disabled:hover {
    pointer-events: none;
  }

  //disabled tab
  .mat-mdc-tab-disabled {
    opacity: 1;
  }

  //diabled tab label
  .mat-mdc-tab-disabled .mdc-tab__content .mdc-tab__text-label {
    color: map.get(palette.$grey-palette, 300);
    font-weight: 300;
    pointer-events: none;
  }
}

//unselected tab border bottom
.mat-tab--border-underline {
  .mdc-tab-indicator {
    border-bottom: 1px solid map.get(palette.$grey-palette, 300);
  }
}

// Tabs - From styles.scss -----------------------------------------------------------------------------------------------
mat-tab-group.mat-mdc-tab-group .mdc-tab--active .mdc-tab-indicator,
mat-tab-group.mat-mdc-tab-group .mdc-tab-indicator__content--underline,
nav.mat-mdc-tab-nav-bar .mdc-tab--active .mdc-tab-indicator,
nav.mat-mdc-tab-nav-bar .mdc-tab-indicator__content--underline {
  border-bottom: 0 none;
}

// TODO: Tab - TEMP fix border on nav tab bar
mat-tab-group.mat-mdc-tab-group .mat-mdc-tab-link-container,
nav.mat-mdc-tab-nav-bar .mat-mdc-tab-link-container {
  border: unset;
  border-bottom: 1px solid variables.$grey-10;
}
// ----------------------------------------------------------------------------------------------- Tabs - From styles
