@use "sass:map";
@use "../../../sass/utils/variables";
@use "../../../sass/utils/palette";

mat-datepicker-content mat-calendar.mat-mdc-calendar {
  span.mat-mdc-button-focus-overlay {
    opacity: 0;
  }

  .mat-mdc-calendar {
    width: 300px;
    height: 355px;
    background: palette.$light-primary-text;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
    border-radius: 3px;
  }

  .mat-mdc-calendar-body-selected {
    color: palette.$light-primary-text !important;
    background-color: map.get(palette.$green-palette, "A900");
  }

  .mat-mdc-calendar-body-today {
    color: palette.$dark-primary-text !important;
    background-color: palette.$light-primary-text !important;
  }
}

mat-form-field.mat-mdc-form-field.datepicker {
  .mat-mdc-form-field-icon-prefix,
  .mat-mdc-form-field-icon-suffix {
    align-self: flex-end;
  }

  mat-datepicker-toggle.mat-datepicker-toggle
    :not(.mat-datepicker-toggle-active) {
    background: transparent !important;
  }

  mat-datepicker-toggle.mat-datepicker-toggle > button.mat-mdc-icon-button {
    & mat-icon.mat-icon {
      color: variables.$black;
      font-size: 100%;
    }
  }
}

mat-datepicker-toggle.mat-datepicker-toggle {
  display: flex;
  align-items: baseline;
  justify-content: flex-end;
  width: 4.5rem;

  mat-form-field.mat-mdc-form-field.mat-mdc-form-field-type-mat-input
    .mat-mdc-form-field-prefix
    .mat-icon,
  mat-form-field.mat-mdc-form-field.mat-mdc-form-field-type-mat-input
    .mat-mdc-form-field-suffix
    .mat-icon {
    line-height: 1.25;
    font-size: 100%;
  }
}

// .mat-form-field-invalid mat-datepicker-toggle.mat-datepicker-toggle::before {
//   content: "";
//   width: 20px;
//   height: 21px;
//   background: url("data:image/svg+xml,%3Csvg width='20' height='21' viewBox='0 0 20 21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10 0.5C4.48 0.5 0 4.98 0 10.5C0 16.02 4.48 20.5 10 20.5C15.52 20.5 20 16.02 20 10.5C20 4.98 15.52 0.5 10 0.5ZM11 15.5H9V13.5H11V15.5ZM11 11.5H9V5.5H11V11.5Z' fill='%23E12621'/%3E%3C/svg%3E");
//   display: block;
//   position: relative;
//   bottom: 0.05rem;
//   border-radius: 50%;
//   animation: invalid_error_icon_animation 1s linear;
// }

.mat-mdc-form-field-type-mat-date-range-input {
  .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined)
    .mat-mdc-form-field-infix {
    padding-top: 24px;
    padding-bottom: 2px;
  }

  .mat-mdc-form-field-infix {
    min-width: 280px;
  }

  .mat-date-range-input-container {
    display: flex;
    align-items: center;
    min-height: 2.5rem;
    font-size: 1.5rem;
    font-weight: 800;
  }

  .mdc-text-field--filled .mdc-line-ripple::after {
    border-bottom-color: black;
  }

  .mat-mdc-icon-button {
    color: black;
  }

  mat-date-range-input:not(.mat-date-range-input-hide-placeholders) {
    .mat-calendar-body-cell-content,
    .mat-date-range-input-separator {
      // color: rgba(0, 0, 0, 0.5);
    }
  }

  .mat-mdc-form-field-icon-prefix,
  .mat-mdc-form-field-icon-suffix {
    align-self: flex-end;
  }

  .mat-mdc-form-field-subscript-wrapper.mat-mdc-form-field-bottom-align {
    margin-top: 0.6rem;
  }

  .mdc-text-field--filled:not(.mdc-text-field--disabled)
    .mdc-line-ripple::after {
    border-bottom-color: black;
  }

  .mat-mdc-form-field-icon-prefix > .mat-icon,
  .mat-mdc-form-field-icon-suffix > .mat-icon {
    padding: 0 !important;
    margin-right: 0 !important;
  }

  .mat-date-range-input-inner {
    caret-color: black;
  }
}

.mat-mdc-form-field-type-mat-date-range-input .mat-mdc-form-field-icon-prefix,
.mat-mdc-form-field-type-mat-date-range-input .mat-mdc-form-field-icon-suffix {
  display: flex;
  align-items: center;
}

mat-datepicker-toggle.mat-datepicker-toggle {
  width: auto;
}

mat-form-field.mat-mdc-form-field.mat-mdc-form-field-type-mat-input:not(
    .datepicker
  )
  .mat-mdc-form-field-icon-prefix,
mat-form-field.mat-mdc-form-field.mat-mdc-form-field-type-mat-input:not(
    .datepicker
  )
  .mat-mdc-form-field-icon-suffix {
  padding: 0;
  margin: 0 !important;
}

// Date picker ---------------------------------------------------------------------------------------------------
mat-datepicker-toggle.mat-datepicker-toggle {
  width: unset !important;
}

.mat-date-range-input-wrapper {
  min-height: 2.5rem;
}
