@use "sass:map";
@use "../../../utils/variables";
@use "../../../utils/palette";

//Button format
%btn {
  border: 2px solid transparent;
  border-radius: 0.188rem;
  font-size: 0.875rem;
  font-weight: bold;
  font-family: "Open Sans";
  letter-spacing: 0.07rem;
  padding: 0.75rem 1.375rem;
  line-height: 1rem;
  text-align: center;
  text-transform: uppercase;
  align-items: center;
  display: inline-flex;
  justify-content: center;
  min-width: max-content;
  height: 3rem;
  color: variables.$black;

  @media (max-width: variables.$max-width-mobile) {
    padding: 0.5rem 0.875rem !important;
    height: 2.5rem;
    font-size: 0.75rem;
    letter-spacing: 0.0525rem;
  }

  &:focus-visible {
    border: 2px solid variables.$black;
  }
}

button.button--small {
  height: 2.25rem !important;

  @media (max-width: variables.$max-width-mobile) {
    height: 2.5rem !important;
  }
}

button.mdc-button.mat-mdc-button-base,
a.mdc-button.mat-mdc-button-base {
  @extend %btn;
  //Mat primary overrides

  &.mat-primary {
    //primary bg
    background-color: map.get(palette.$green-palette, 500);
    //primary btn hover
    &:hover {
      background-color: map.get(palette.$green-palette, 300);
    }

    &:focus,
    &:focus-visible {
      background-color: map.get(palette.$green-palette, 300);
    }

    //primary btn disable
    &:disabled {
      background-color: map.get(palette.$grey-palette, 200);
      color: map.get(palette.$grey-palette, 500);
    }
  }

  //Mat secondary overrides
  &.mat-secondary {
    background-color: variables.$white;
    color: variables.$black;
    border: 1px solid variables.$black;
    margin-left: 1px;
    //secondary btn hover
    &:hover {
      background-color: map.get(palette.$grey-palette, 200);
    }

    //secondary btn focus
    &:focus-visible {
      border: 2px solid variables.$black;
      background-color: map.get(palette.$grey-palette, 200);
      margin-left: 0;
    }

    //secondary btn disable
    &:disabled {
      background: none;
      border: 1px solid map.get(palette.$grey-palette, 300);
      color: map.get(palette.$grey-palette, 500);
    }
  }

  //Mat reverse overrides
  &.mat-reverse {
    background-color: variables.$black;
    color: variables.$white;
    border: 1px solid variables.$white;
    border-radius: 1px;
    margin-left: 1px;

    //reverse hover
    &:hover {
      background-color: map.get(palette.$grey-palette, 900);
    }

    //reverse focus
    &:focus-visible {
      border: 2px solid variables.$white;
      background-color: map.get(palette.$grey-palette, 900);
      border-radius: 1px;
      // margin: 0;
      margin-left: 0;
    }
  }

  //Mat tertiary
  &.mat-tertiary {
    background-color: variables.$black;
    color: variables.$white;
    border: 1px solid variables.$white;
    margin-left: 1px;
    //tertiary hover
    &:hover {
      background-color: map.get(palette.$grey-palette, 900);
    }

    //tertiary focus
    &:focus-visible {
      border: 2px solid variables.$white;
      background-color: map.get(palette.$grey-palette, 900);
      box-shadow: 0 0 0 2px variables.$black;
      margin-left: 0;
    }
  }

  //all btn types disabled overrides
  &:disabled,
  &.mat-tertiary:disabled,
  &.mat-reverse:disabled {
    background-color: map.get(palette.$grey-palette, 200);
    color: map.get(palette.$grey-palette, 500);
  }

  //mat-action overrides
  &.mat-text,
  &.mat-action {
    border: 2px solid transparent;
    border-radius: 0.188rem;
    font-size: 0.875rem;
    font-weight: bold;
    letter-spacing: 0.07rem;
    padding: 0 0.25rem;
    line-height: 1rem;
    text-align: center;
    text-transform: uppercase;
    align-items: center;
    display: inline-flex;
    justify-content: center;
    min-width: max-content;
    height: 1.875rem;
    color: variables.$black;
    background: transparent;
    gap: 0.25rem;
    //mat icon color
    mat-icon.mat-icon {
      color: map.get(palette.$green-palette, 900);
      margin-right: 0rem;
      z-index: 1;
      font-size: 1.5rem;
      height: 1.5rem;
      width: 1.5rem;
    }

    .mat-mdc-focus-indicator {
      background-color: transparent;
    }

    //icon btn hover
    &:hover {
      background-color: transparent;

      mat-icon.mat-icon {
        color: map.get(palette.$green-palette, 800);
      }
    }

    //icon btn focus
    &:focus-visible,
    &:focus {
      border: 2px solid variables.$black;

      .mat-mdc-button-persistent-ripple,
      .mdc-button__ripple {
        display: none;
      }
    }

    //icon btn disabled
    &:disabled {
      color: map.get(palette.$grey-palette, 300);

      mat-icon.mat-icon {
        color: map.get(palette.$green-palette, 900);
        opacity: 0.8;
      }
    }
  }

  &.mat-icon {
    border: 2px solid transparent;
    border-radius: 0.188rem;
    font-size: 0.875rem;
    font-weight: bold;
    letter-spacing: 0.07rem;
    padding: 0 0.25rem;
    line-height: 1rem;
    text-align: center;
    text-transform: uppercase;
    align-items: center;
    display: inline-flex;
    justify-content: center;
    min-width: max-content;
    height: 1.875rem;
    color: variables.$black;
    background: transparent;
    gap: 0.25rem;
    //mat icon color
    mat-icon.mat-icon {
      color: black;
      margin-right: 0rem;
      z-index: 1;
      font-size: 1.5rem;
      height: 1.5rem;
      width: 1.5rem;
    }

    &:hover {
      mat-icon.mat-icon {
        color: map.get(palette.$green-palette, 800);
      }
    }
  }

  //Mat split overrides
  &.mat-menu,
  &.mat-split {
    background-color: map.get(palette.$green-palette, 500);
    //spit btn hover bg
    &:hover {
      background-color: map.get(palette.$green-palette, 300);
    }

    //split btn disabled bg
    &:disabled {
      background-color: map.get(palette.$grey-palette, 200);
      color: map.get(palette.$grey-palette, 500);
    }
  }

  //Mat warn overrides
  &.mat-warn {
    //warn bg
    background-color: map.get(palette.$red-palette, "A400");
    color: variables.$white;
    //warn btn hover
    &:hover {
      background-color: map.get(palette.$red-palette, "A100");
    }

    //warn btn disable
    &:disabled {
      background-color: map.get(palette.$grey-palette, 200);
      color: map.get(palette.$grey-palette, 500);
    }
  }
}

// Ripple effect
.mat-mdc-button:hover .mat-mdc-button-persistent-ripple::before,
.mat-mdc-outlined-button:hover .mat-mdc-button-persistent-ripple::before {
  background: transparent;
}

// TODO: Unknown - find and replace/remove
a[mat-button] {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0.25rem;
  height: 3rem;
  min-width: max-content;

  .mat-button-wrapper {
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 1rem;
    letter-spacing: 0.07em;
    text-transform: uppercase;
  }

  &.mat-action mat-icon.mat-icon {
    color: variables.$green-darker;
  }

  mat-icon.mat-icon {
    width: auto;
    padding-right: 0.375rem;
  }
}

// TODO: Button Remove over shadow
.mat-mdc-icon-button {
  &.mat-action mat-icon.mat-icon {
    color: variables.$grey-50;

    &:hover {
      color: variables.$grey-75;
    }
  }

  .mat-mdc-button-persistent-ripple {
    visibility: hidden;
  }
}
