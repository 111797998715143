@use "sass:map";
@use "../../../sass/utils/variables";
@use "../../../sass/utils/palette";

.mat-calendar {
  width: 18.6rem;
  height: 22.1875rem;
  background: palette.$white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  border-radius: 3px;
}

// TODO: Calendar - Hiding calendar today's border
.mat-calendar-body-today:not(.mat-calendar-body-selected):not(
    .mat-calendar-body-comparison-identical
  ) {
  border: none !important;
}
