@use "sass:map";
@use "../../utils/variables";
@use "../../utils/palette";

// Remove over shadow
.mat-mdc-icon-button .mat-mdc-button-persistent-ripple {
  visibility: hidden;
}

button.row-icon-button {
  // Default button style
  mat-icon.mat-icon {
    color: variables.$grey-50;
  }

  // Hover state
  &:hover mat-icon.mat-icon {
    color: variables.$grey-75;
  }

  // Disabled buttons
  &:disabled mat-icon.mat-icon {
    color: variables.$grey-25;
  }
}
