mat-form-field.mat-mdc-form-field.mat-mdc-form-field-type-mat-select:not(
    .mat-mdc-paginator-page-size-select
  ) {
  mat-select.mat-mdc-select-multiple {
    line-height: unset;

    .mat-mdc-select-value {
      font-size: 1.5rem;
      min-height: 1.7rem;
    }
  }
}

// Font size for multi-select with chips
mat-form-field.mat-mdc-form-field.mat-mdc-form-field-type-mat-select:not(
    .mat-mdc-paginator-page-size-select
  ) {
  mat-select.mat-mdc-select-multiple
    .mat-mdc-select-value
    .mdc-evolution-chip__text-label {
    font-size: 1rem;
  }
}
