@use "sass:map";
@use "../../../utils/variables";
@use "../../../utils/palette";

table.mat-mdc-table {
  // Most of the time, table width is needed so it's defaulted to 100%
  width: 100%;

  // Headers match GA typography
  thead > tr > th.mat-mdc-header-cell {
    color: variables.$black;
    font-weight: 800;
    letter-spacing: 1.5px;
    font-size: 0.75rem;
    text-transform: uppercase;
    white-space: nowrap;
  }

  // Footers match GA typography
  .mat-mdc-footer-cell {
    font-size: 1rem;
  }

  //  Table data matches GA typography
  tr.mat-mdc-row > td.mat-mdc-cell {
    font-size: 1rem;
    color: variables.$black;
    font-weight: 100;
    line-height: 1.5;
    white-space: nowrap;
  }

  .mdc-data-table__row .mdc-data-table__cell,
  .mat-mdc-paginator {
    border-bottom: 1px solid variables.$grey-10;
  }
}

// From global.scss - needs revisiting
// TODO: Table ---------------------------------------------------------------------------------------------------
// TODO - Table styles -----------------------------------------------------------------------------------------------
.mdc-table-fixed-layout {
  white-space: nowrap !important;
  table-layout: fixed !important;
}

th.mat-mdc-header-cell,
td.mat-mdc-cell,
td.mat-mdc-footer-cell {
  &.truncated-text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 0;
    padding-right: 0.5rem;
  }
}

th .mat-sort-header-container {
  letter-spacing: inherit;
  border-bottom: none !important;

  .mat-sort-header-arrow {
    color: variables.$green-dark;
  }

  .mat-sort-header-content {
    text-align: unset;
  }
}

// TODO Needs to be added to the UI-Kit
table.mat-mdc-table
  tr.mat-mdc-row
  > td.mat-mdc-cell.highlighted
  mat-select.mat-mdc-select
  .mat-mdc-select-trigger
  .mat-mdc-select-value
  .mat-mdc-select-min-line {
  font-weight: 600;
}

.mat-mdc-row .mat-mdc-cell,
.mat-mdc-paginator {
  border-bottom: 1px solid variables.$grey-10;
}

mat-table.mat-mdc-table {
  mat-row.mat-mdc-row > mat-cell.mat-mdc-cell {
    height: 56px;

    mat-icon.mat-icon {
      color: variables.$grey-50;
      cursor: pointer;
    }

    & .mat-mdc-menu-trigger.selected[aria-expanded="true"] mat-icon.mat-icon {
      color: green;

      &:hover {
        color: black;
      }
    }

    &:not(:first-child):has(mat-select) {
      margin-right: 2rem;
    }
  }

  // Headers match GA typography
  mat-header-row.mat-mdc-header-row > mat-header-cell.mat-mdc-header-cell {
    color: variables.$black;
    font-weight: 800;
    letter-spacing: 1.5px;
    font-size: 0.75rem;
    text-transform: uppercase;
    white-space: nowrap;
  }

  //  Table data matches GA typography
  mat-row.mat-mdc-row > td.mat-mdc-cell {
    font-size: 1rem;
    color: variables.$black;
    font-weight: 100;
    white-space: nowrap;
  }

  mat-row.mat-mdc-row > mat-cell.mat-mdc-cell,
  mat-header-row.mat-mdc-header-row > mat-header-cell.mat-mdc-header-cell {
    &.mat-mdc-table-sticky:first-of-type,
    &.mat-mdc-table-sticky.mat-mdc-table-sticky-border-elem-right {
      box-shadow: -1.2px 10px 0.6rem rgba(0, 0, 0, 0.2);
    }

    &.mat-mdc-table-sticky.mat-mdc-table-sticky-border-elem-right {
      padding-left: 1.5rem;
      width: 0;
      white-space: nowrap;
    }
  }

  // Add some spacing to account for shadow from 1st column - cleaner look
  mat-header-row.mat-mdc-header-row > mat-header-cell.mat-mdc-header-cell,
  mat-row.mat-mdc-row > mat-cell.mat-mdc-cell {
    &.mat-mdc-table-sticky:first-of-type + mat-header-cell,
    &.mat-mdc-table-sticky:first-of-type + mat-cell {
      padding-left: 1rem;
    }
  }
}

// TODO: table pagination styles
.mat-mdc-paginator-page-size-value {
  font-size: 1rem;
  font-weight: 600;
}

// ---------------------------------------------------------------------------------------------------
