@use "sass:map";

// mixin for mat-table column widths
@forward "../components/angular-material/mat-table/mat-table--column-mixin";

@mixin center-absolute($horizontal: true, $vertical: true) {
  position: absolute;

  @if ($horizontal and $vertical) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  } @else if ($horizontal) {
    left: 50%;
    transform: translateX(-50%);
  } @else if ($vertical) {
    top: 50%;
    transform: translateY(-50%);
  }
}

@mixin hint {
  font-size: 14px !important;
}
