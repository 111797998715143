@use "../../../sass/utils/variables";

.mat-mdc-tooltip.mat-mdc-tooltip-error,
.mat-mdc-tooltip.mat-mdc-tooltip-invalid {
  .mdc-tooltip__surface {
    margin: 0.5rem;
    padding: 0.75rem 1rem;
    color: variables.$black;
    font-size: 0.75rem;
    line-height: 1rem;
    white-space: pre-wrap;
  }
}

.mat-mdc-tooltip.mat-mdc-tooltip-error .mdc-tooltip__surface {
  background: variables.$red-wash;
}

.mat-mdc-tooltip.mat-mdc-tooltip-invalid .mdc-tooltip__surface {
  background: variables.$grey-3;
}

.mat-mdc-tooltip .mdc-tooltip__surface {
  background: #f3f3f3 !important;
  color: #333 !important;
}
