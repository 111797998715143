@use "../../../utils/variables";

// Expansion header arrow
mat-expansion-panel-header.mat-expansion-panel-header
  .mat-expansion-indicator::after {
  content: "";
  display: block;
  width: 18px;
  height: 10px;
  transform: translateY(0.1rem) !important;
  padding: 0 !important;
  border: none;
  background-image: url("data:image/svg+xml,%3Csvg width='18' height='10' viewBox='0 0 18 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8.86328 8.02165L16.374 0.510949L17.0811 1.21806L8.86328 9.43587L0.64547 1.21806L1.35258 0.510949L8.86328 8.02165Z' fill='black'/%3E%3C/svg%3E%0A");
}

// TODO: Unkown styles - find and replace/remove
.panel-description {
  padding: 1.5rem;
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.5;
  width: 702px;
}

// TODO: expansion panel styles
.mat-expansion-panel-body {
  overflow-y: hidden;
  overflow-x: auto;
}
.mat-expansion-panel-padding-min
  .mat-expansion-panel-content
  .mat-expansion-panel-body {
  padding: 0 !important;
}
