@use "sass:map";
@use "../../../utils/variables";
@use "../../../utils/palette";

mat-paginator.mat-mdc-paginator {
  border-bottom: 1px solid variables.$grey-10 !important;

  .mat-mdc-paginator-container {
    justify-content: space-between;
    //pagination actions
    .mat-mdc-paginator-range-actions {
      .mat-mdc-paginator-range-label {
        font-size: 1rem;
        color: palette.$black;
        font-weight: 300;
      }

      .mat-button-wrapper {
        color: map.get(palette.$grey-palette, "A200");
      }
    }
  }

  .mat-mdc-select-min-line {
    font-size: 1rem;
    font-weight: 600;
  }

  //Page dropdown
  .mat-mdc-paginator-page-size {
    gap: 1rem;
    // align-items: center;
  }

  .mat-mdc-select-arrow-wrapper::after {
    all: revert;
    background-image: url("data:image/svg+xml,%3Csvg width='12' height='8' viewBox='0 0 12 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.41 0.589844L6 5.16984L10.59 0.589844L12 1.99984L6 7.99984L0 1.99984L1.41 0.589844Z' fill='%238B8B8B'/%3E%3C/svg%3E%0A");
    content: "";
    display: block;
    width: 12px;
    height: 8px;
  }

  .mat-mdc-select-trigger {
    border-bottom: 1px solid #bfbfbf;
    width: auto;
  }

  .mat-mdc-select-arrow svg {
    display: none;
  }

  //Page dropdown alignment
  .mat-mdc-paginator-page-size-select {
    margin: 0;
    width: 3rem;
  }

  .mdc-notched-outline__leading,
  .mdc-notched-outline__notch,
  .mdc-notched-outline__trailing {
    border: none;
  }

  .mdc-notched-outline__leading {
    border: none;
  }

  .mdc-notched-outline__trailing {
    border: none;
  }

  //Page dropdown alignment
  .mat-mdc-form-field-wrapper {
    //padding-bottom: 1.75rem;
    width: 3.125rem;
  }

  .mat-mdc-form-field-appearance-standard {
    /* .mat-select-arrow-wrapper {
            transform: none;
        }*/
    //Page dropdown padding
    .mat-mdc-form-field-flex {
      padding: 0;
      //height: 0;
      background-color: transparent;
    }

    //Page dropdown alignment
    .mat-mdc-form-field-infix {
      padding: 0;
      border: none;
      //arrow alignment
      .mat-select-arrow {
        width: unset;
        height: 0;
        border-left: none;
        border-right: none;
        border-top: none;
        margin: 0;
      }

      .mat-mdc-select-arrow-wrapper {
        /* position: unset;
                display: flex;
                justify-content: center;*/
        transform: translate(-4px, -4px);

        .mat-mdc-select-arrow:after {
          all: revert;
          background-image: url("data:image/svg+xml,%3Csvg width='12' height='8' viewBox='0 0 12 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.41 0.589844L6 5.16984L10.59 0.589844L12 1.99984L6 7.99984L0 1.99984L1.41 0.589844Z' fill='%238B8B8B'/%3E%3C/svg%3E%0A");
          content: "";
          display: block;
          width: 12px;
          height: 8px;
        }
      }
    }
  }

  //Paginator label format
  .mat-mdc-paginator-page-size-label {
    font-size: 1rem;
    font-weight: 300;
    color: palette.$black;
  }

  //Page number select value format
  .mat-mdc-paginator,
  .mat-mdc-paginator-page-size .mat-select-trigger {
    color: palette.$black;

    span.mat-select-min-line {
      font-size: 1rem;
      font-weight: 600;
    }
  }

  .mat-mdc-form-field-underline > .mat-mdc-form-field-ripple {
    color: palette.$black;
  }

  //On foused select arrow
  .mat-mdc-form-field.mat-focused.mat-primary .mat-select-arrow {
    color: palette.$black;
  }
}

// remove color
.cdk-overlay-container {
  .mat-select-panel-wrap {
    mat-option.mat-option {
      .mat-option-text {
        color: palette.$black;
      }
    }
  }
}

// Paginator drop down
.mdc-list-item--selected .mdc-list-item__primary-text,
.mdc-list-item--activated .mdc-list-item__primary-text {
  color: black !important;
}
