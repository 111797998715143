@use "sass:map";
@use "../../../utils/variables";

table {
  .cdk-column-actions.mat-column-actions {
    mat-icon {
      cursor: pointer;
    }
  }
}

// Styles for bulk action buttons and row action buttons

table.mat-mdc-table
  tr.mat-mdc-row
  .mat-column-actions.cdk-column-actions.mat-column-actions,
gax-page-section-table-header-bulk-actions {
  // Default button style
  mat-icon.mat-icon {
    color: variables.$grey-50;
  }

  // Hover state
  button:hover mat-icon.mat-icon,
  a:hover mat-icon.mat-icon {
    color: variables.$grey-75;
  }

  // Disabled buttons
  button:disabled mat-icon.mat-icon {
    color: variables.$grey-25;
  }
}

table.mat-mdc-table tr.mat-mdc-row {
  .mat-column-actions,
  .mat-column--actions {
    white-space: nowrap;
    width: 0;
    padding: 0 0.5rem;

    > * {
      &:not(button, .mat-mdc-tooltip-trigger) {
        display: flex;
      }
    }

    .mat-mdc-tooltip-trigger:not(button) {
      display: inline-flex;
    }

    button {
      margin: 0;
      width: auto;
      height: auto;
      padding: 0.5rem;
      display: inline-flex;
    }
  }

  // expandable row table arrow
  .expand-btn {
    transition: transform 0.3s;
    cursor: pointer;
    padding-top: 0.1rem;

    &.expanded {
      transform: rotate(180deg);
      transition: transform 0.3s;
    }
  }
}
