// Reset styles
* {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
}

html,
body,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

// Number input - hide spinner
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
