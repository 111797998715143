@use "sass:map";
@use "../../../utils/variables";
@use "../../../utils/palette";

mat-radio-group.mat-mdc-radio-group {
  // display: block;
  //space between radios
  .mat-mdc-radio-button {
    margin-right: 1rem;
  }

  //radio label format to match GA typography
  .mdc-form-field label {
    font-weight: 600;
    font-size: 1rem;
    color: variables.$black;
  }

  //radio outercircle bg&background to match GA typography
  mat-radio-button.mat-mdc-radio-button
    .mdc-radio
    .mdc-radio__native-control:enabled:checked
    + .mdc-radio__background
    .mdc-radio__outer-circle {
    background: palette.$light-primary-text;
    border: 2px solid map.get(palette.$green-palette, "A900");
  }

  //radio innercircle bg&background to match GA typography
  .mat-mdc-radio-button
    .mdc-radio
    .mdc-radio__native-control:enabled
    + .mdc-radio__background
    .mdc-radio__inner-circle {
    border-color: map.get(palette.$green-palette, "A900");
  }

  //radio innercircle bg to match GA typography
  .mat-mdc-radio-button.mat-accent.mat-mdc-radio-checked
    .mdc-radio
    .mdc-radio__native-control:enabled
    + .mdc-radio__background
    .mdc-radio__inner-circle {
    border-color: map.get(palette.$green-palette, "A900");
    -webkit-transform: scale(0.57);
    transform: scale(0.57);
    transition: 0.1s all linear;
    background-color: map.get(palette.$green-palette, "A900");
  }

  //radio circle when not checked
  .mat-mdc-radio-button
    .mdc-radio
    .mdc-radio__native-control:enabled:not(:checked)
    + .mdc-radio__background
    .mdc-radio__outer-circle {
    border-color: variables.$grey-50;
  }

  //outer circle tab focus
  mat-radio-button.mat-mdc-radio-button
    .mdc-radio
    .mdc-radio__native-control:enabled:checked:focus-visible
    + .mdc-radio__background
    .mdc-radio__outer-circle {
    box-shadow: 0 0 0 2px variables.$black;
    background-color: variables.$white;
  }

  //outer circle focus
  mat-radio-button.mat-mdc-radio-button
    .mdc-radio
    .mdc-radio__native-control:enabled:checked:focus
    + .mdc-radio__background
    .mdc-radio__outer-circle {
    background: palette.$light-primary-text;
    border: 2px solid map.get(palette.$green-palette, "A900");
  }

  .mat-mdc-radio-button.mat-mdc-radio-checked .mdc-radio__background::before {
    background-color: map.get(palette.$green-palette, "A900");
  }

  //mat ripple
  .mat-mdc-radio-button.mat-mdc-radio-checked .mat-ripple-element {
    background-color: map.get(palette.$green-palette, "A900");
  }

  // mdc-radio outer focus
  .mat-mdc-radio-button
    .mdc-radio:not(.mdc-ripple-upgraded):focus
    .mdc-radio__native-control:enabled:checked
    + .mdc-radio__background
    .mdc-radio__outer-circle {
    border-color: map.get(palette.$green-palette, "A900");
  }

  //mdc-radio inner focus
  .mat-mdc-radio-button
    .mdc-radio:not(.mdc-ripple-upgraded):focus
    .mdc-radio__native-control:enabled
    + .mdc-radio__background
    .mdc-radio__inner-circle {
    border-color: map.get(palette.$green-palette, "A900");
  }

  //mat mdc inner focus
  .mat-mdc-radio-button
    .mdc-radio
    .mdc-radio__native-control:enabled:focus
    + .mdc-radio__background
    .mdc-radio__inner-circle {
    background-color: map.get(palette.$green-palette, "A900");
  }

  .mat-mdc-radio-button
    .mdc-radio
    .mdc-radio__native-control:enabled:not(:checked):focus-visible
    + .mdc-radio__background
    .mdc-radio__outer-circle {
    box-shadow: 0 0 0 2px variables.$black;
  }

  //inner circle on focus
  .mat-mdc-radio-button
    .mdc-radio:not(:disabled):active
    .mdc-radio__native-control:enabled
    + .mdc-radio__background
    .mdc-radio__inner-circle {
    border-color: map.get(palette.$green-palette, "A900");
  }

  //disabled circle
  .mat-mdc-radio-button
    .mdc-radio
    [aria-disabled="true"]
    .mdc-radio__native-control:not(:checked)
    + .mdc-radio__background
    .mdc-radio__outer-circle,
  .mat-mdc-radio-button
    .mdc-radio
    .mdc-radio__native-control:disabled:not(:checked)
    + .mdc-radio__background
    .mdc-radio__outer-circle {
    border-color: variables.$grey-25;
    opacity: 1;
  }

  //disabled label
  .mat-mdc-radio-button.mat-accent .mdc-radio--disabled + label {
    color: variables.$grey-25;
    font-weight: 600;
  }
}
