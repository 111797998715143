@use "sass:map";
@use "../../utils/variables";
@use "../../utils/palette";

//info panel format
.ga-info-alert-panel {
  background-color: map.get(palette.$green-palette, "A100");
  padding: 1rem;
  border-radius: 3px;
  display: flex;
}

//error panel format
.ga-error-alert-panel {
  background-color: variables.$red-wash;
  padding: 1rem;
  border-radius: 3px;
  display: flex;
}

//warning panel format
.ga-warning-alert-panel {
  background-color: variables.$yellow-wash;
  padding: 1rem;
  border-radius: 3px;
  display: flex;
}

//info panel icon color
.ga-info-alert-panel-icon {
  color: variables.$green-slightly-darker; //map.get(palette.$green-palette, "A600");
}

//error panel icon color
.ga-error-alert-panel-icon {
  color: map.get(palette.$red-palette, "A400");
}

//warning panel icon color
.ga-warning-alert-panel-icon {
  position: relative; /* Make it the parent for positioning */
  color: #ecbc02;
}

//warning panel (icon color exclamation mark black from transparent)
.ga-warning-alert-panel-icon::after {
  content: "!"; /* Center mark */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: black; /* Exclamation mark color */
  font-size: 0.6em; /* Adjust size relative to the icon */
  font-weight: bold; /* Make it bold to match the Material Icon style */
}

//panel title format
.ga-alert-panel-title {
  margin-bottom: 5px;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

//panel desc format
.ga-alert-panel-description {
  font-weight: 300;
  margin-right: 2rem;
}

//panel format
.alert-panel-content {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
}

gax-alert-panel {
  a {
    color: #000;
    font-weight: 600;
    text-decoration: underline;
    cursor: pointer;
  }
}
