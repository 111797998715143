// Snackbar
@use "../../../sass/utils/variables";

.red-snackbar {
  color: variables.$red;
}

snack-bar-container.mat-snack-bar-container {
  background-color: variables.$red-wash;
  color: variables.$black;
  border-right: 4px solid variables.$red;
  margin-left: 248px;

  .mat-simple-snackbar-action button span {
    color: variables.$red;
  }
}

// End of Snackbar
