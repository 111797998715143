// // Typography styles
@use "../utils/variables";

@import url("https://fonts.googleapis.com/css2?family=Material+Icons");

@font-face {
  font-display: swap;
  font-family: "Open Sans";
  src: url("../../fonts/OpenSans/OpenSans-LightItalic.ttf");
  font-style: italic;
  font-weight: 300;
  font-stretch: 100%;
  font-display: swap;
}

@font-face {
  font-display: swap;
  font-family: "Open Sans";
  src: url("../../fonts/OpenSans/OpenSans-Italic.ttf");
  font-style: italic;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
}

@font-face {
  font-display: swap;
  font-family: "Open Sans";
  src: url("../../fonts/OpenSans/OpenSans-Light.ttf");
  font-weight: 300;
  font-stretch: 100%;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-display: swap;
  font-family: "Open Sans";
  src: url("../../fonts/OpenSans/OpenSans-Regular.ttf");
  font-weight: normal;
  font-stretch: 100%;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-display: swap;
  font-family: "Open Sans";
  src: url("../../fonts/OpenSans/OpenSans-Medium.ttf");
  font-weight: medium;
  font-stretch: 100%;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-display: swap;
  font-family: "Open Sans";
  src: url("../../fonts/OpenSans/OpenSans-SemiBold.ttf");
  font-weight: 600;
  font-stretch: 100%;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-display: swap;
  font-family: "Open Sans";
  src: url("../../fonts/OpenSans/OpenSans-Bold.ttf");
  font-weight: 700;
  font-stretch: 100%;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-display: swap;
  font-family: "Open Sans";
  src: url("../../fonts/OpenSans/OpenSans-ExtraBold.ttf");
  font-weight: 800;
  font-stretch: 100%;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-display: swap;
  font-family: "OpenSans-Light";
  src: url("../../fonts/OpenSans/OpenSans-Light.ttf");
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-display: swap;
  font-family: "OpenSans";
  src: url("../../fonts/OpenSans/OpenSans-Regular.ttf");
  font-weight: normal;
}

@font-face {
  font-display: swap;
  font-family: "OpenSans-SemiBold";
  src: url("../../fonts/OpenSans/OpenSans-SemiBold.ttf");
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-display: swap;
  font-family: "OpenSans-Bold";
  src: url("../../fonts/OpenSans/OpenSans-Bold.ttf");
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-display: swap;
  font-family: "OpenSans-ExtraBold";
  src: url("../../fonts/OpenSans/OpenSans-ExtraBold.ttf");
  font-weight: 800;
  font-style: normal;
  font-stretch: normal;
}

$font: "Open Sans", Arial;

body {
  font-family: $font, sans-serif;
}

h1 {
  font-size: variables.$font-size-header1;
  line-height: 125%;
  letter-spacing: variables.$letter-spacing-small;
}

h2 {
  font-size: variables.$font-size-header2;
  font-weight: 800;
  line-height: 125%;
  letter-spacing: normal;
}

h3 {
  font-size: variables.$font-size-header3;
  line-height: 120%;
  letter-spacing: normal;
}

h4 {
  font-size: variables.$font-size-header4;
  line-height: 150%;
  letter-spacing: variables.$letter-spacing-medium;
}

p {
  margin: 0 0 1.5em 0;
  font-weight: 300;
  color: black;
  letter-spacing: 0.03125em;
}
