@use "sass:map";
@use "./variables";

$black: variables.$black;
$white: variables.$white;

$dark-primary-text: rgba(black, 0.87);
$dark-secondary-text: rgba(black, 0.54);
$dark-disabled-text: rgba(black, 0.38);
$dark-dividers: rgba(black, 0.12);
$dark-focused: rgba(black, 0.12);
$light-primary-text: white;
$light-secondary-text: rgba(white, 0.7);
$light-disabled-text: rgba(white, 0.5);
$light-dividers: rgba(white, 0.12);
$light-focused: rgba(white, 0.12);

$red-palette: (
  50: variables.$red-wash-light,
  100: variables.$red-wash,
  200: variables.$red-light,
  300: variables.$red-light,
  400: variables.$red,
  500: variables.$red,
  600: variables.$red,
  700: variables.$red-dark,
  800: variables.$red-dark,
  900: variables.$red-dark,
  A100: variables.$red-light,
  A200: variables.$red,
  A400: variables.$red,
  A700: variables.$red-dark,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  ),
);

$blue-palette: (
  50: variables.$blue-wash,
  100: variables.$blue-light,
  200: variables.$blue-light,
  300: variables.$blue-light,
  400: variables.$blue,
  500: variables.$blue,
  600: variables.$blue,
  700: variables.$blue-dark,
  800: variables.$blue-dark,
  900: variables.$blue-dark,
  A100: variables.$blue-light,
  A200: variables.$blue,
  A400: variables.$blue,
  A700: variables.$blue-dark,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  ),
);

$teal-palette: (
  50: variables.$teal-wash,
  100: variables.$teal-light,
  200: variables.$teal-light,
  300: variables.$teal-light,
  400: variables.$teal,
  500: variables.$teal,
  600: variables.$teal,
  700: variables.$teal-dark,
  800: variables.$teal-dark,
  900: variables.$teal-dark,
  A100: variables.$teal-wash,
  A200: variables.$teal-light,
  A400: variables.$teal,
  A700: variables.$teal-dark,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $dark-primary-text,
    A700: $dark-primary-text,
  ),
);

$green-palette: (
  50: variables.$green-wash-light,
  100: variables.$green-wash,
  200: variables.$green-light,
  300: variables.$green-light,
  400: variables.$green,
  500: variables.$green,
  600: variables.$green,
  700: variables.$green-dark,
  800: variables.$green-dark,
  900: variables.$green-darker,
  A100: variables.$green-wash,
  A200: variables.$green-light,
  A400: variables.$green-dark,
  A700: variables.$green-deloitte,
  A900: variables.$green-darker,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $dark-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  ),
);

$yellow-palette: (
  50: variables.$yellow-wash-light,
  100: variables.$yellow-wash,
  200: variables.$yellow-light,
  300: variables.$yellow-light,
  400: variables.$yellow,
  500: variables.$yellow,
  600: variables.$yellow,
  700: variables.$yellow-dark,
  800: variables.$yellow-dark,
  900: variables.$yellow-dark,
  A100: variables.$yellow-wash,
  A200: variables.$yellow-light,
  A400: variables.$yellow,
  A700: variables.$yellow-dark,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $dark-primary-text,
    600: $dark-primary-text,
    700: $dark-primary-text,
    800: $dark-primary-text,
    900: $dark-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $dark-primary-text,
    A700: $dark-primary-text,
  ),
);

$orange-palette: (
  50: variables.$orange-wash,
  100: variables.$orange-light,
  200: variables.$orange-light,
  300: variables.$orange-light,
  400: variables.$orange,
  500: variables.$orange,
  600: variables.$orange,
  700: variables.$orange-dark,
  800: variables.$orange-dark,
  900: variables.$orange-dark,
  A100: variables.$orange-wash,
  A200: variables.$orange-light,
  A400: variables.$orange,
  A700: variables.$orange-dark,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $dark-primary-text,
    600: $dark-primary-text,
    700: $dark-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $dark-primary-text,
    A700: black,
  ),
);

$grey-palette: (
  50: variables.$grey,
  100: variables.$grey-3,
  200: variables.$grey-10,
  300: variables.$grey-25,
  400: variables.$grey-50,
  500: variables.$grey-50,
  600: variables.$grey-50,
  700: variables.$grey-75,
  800: variables.$grey-75,
  900: variables.$grey-85,
  A100: variables.$grey-25,
  A200: variables.$grey-50,
  A400: variables.$grey-75,
  A700: variables.$grey-85,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $dark-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $dark-primary-text,
    A700: $light-primary-text,
  ),
);

// Alias for alternate spelling.
$gray-palette: $grey-palette;

// Background palette for light themes.
$light-theme-background-palette: (
  //   status-bar: map.get($grey-palette, 300),
  //   app-bar:    map.get($grey-palette, 100),
  background: map.get($grey-palette, 100),
  //   hover:      rgba(black, 0.04), // TODO(kara): check style with Material Design UX
  //   card:       white,
  //   dialog:     white,
  //   disabled-button: rgba(black, 0.12),
  //   raised-button: white,
  //   focused-button: $dark-focused,
  //   selected-button: map.get($grey-palette, 300),
  //   selected-disabled-button: map.get($grey-palette, 400),
  //   disabled-button-toggle: map.get($grey-palette, 200),
  //   unselected-chip: map.get($grey-palette, 300),
  //   disabled-list-option: map.get($grey-palette, 200),
   //   tooltip: map.get($grey-palette, 700),
);

// // Background palette for dark themes.
// $dark-theme-background-palette: (
//   status-bar: black,
//   app-bar:    map.get($grey-palette, 900),
//   background: #303030,
//   hover:      rgba(white, 0.04), // TODO(kara): check style with Material Design UX
//   card:       map.get($grey-palette, 800),
//   dialog:     map.get($grey-palette, 800),
//   disabled-button: rgba(white, 0.12),
//   raised-button: map.get($grey-palette, 800),
//   focused-button: $light-focused,
//   selected-button: map.get($grey-palette, 900),
//   selected-disabled-button: map.get($grey-palette, 800),
//   disabled-button-toggle: black,
//   unselected-chip: map.get($grey-palette, 700),
//   disabled-list-option: rgba(white, 0.12),
//   tooltip: map.get($grey-palette, 700),
// );

// // Foreground palette for light themes.
// $light-theme-foreground-palette: (
//   base:              black,
//   divider:           $dark-dividers,
//   dividers:          $dark-dividers,
//   disabled:          $dark-disabled-text,
//   disabled-button:   rgba(black, 0.26),
//   disabled-text:     $dark-disabled-text,
//   elevation:         black,
//   hint-text:         $dark-disabled-text,
//   secondary-text:    $dark-secondary-text,
//   icon:              rgba(black, 0.54),
//   icons:             rgba(black, 0.54),
//   text:              rgba(black, 0.87),
//   slider-min:        rgba(black, 0.87),
//   slider-off:        rgba(black, 0.26),
//   slider-off-active: rgba(black, 0.38),
// );

// // Foreground palette for dark themes.
// $dark-theme-foreground-palette: (
//   base:              white,
//   divider:           $light-dividers,
//   dividers:          $light-dividers,
//   disabled:          $light-disabled-text,
//   disabled-button:   rgba(white, 0.3),
//   disabled-text:     $light-disabled-text,
//   elevation:         black,
//   hint-text:         $light-disabled-text,
//   secondary-text:    $light-secondary-text,
//   icon:              white,
//   icons:             white,
//   text:              white,
//   slider-min:        white,
//   slider-off:        rgba(white, 0.3),
//   slider-off-active: rgba(white, 0.3),
// );
