@use "sass:map";
@use "../../../utils/variables";
@use "../../../utils/palette";

mat-form-field.mat-mdc-form-field.mat-mdc-form-field-type-mat-input {
  //content format
  .mat-mdc-input-element {
    font-weight: 800 !important;
    text-overflow: ellipsis;
  }

  &:not(.datepicker) {
    .mat-mdc-form-field-icon-prefix,
    .mat-mdc-form-field-icon-suffix {
      align-self: flex-end;
      // padding-bottom: 0.5rem;
      margin-right: 0.3rem;
    }
  }

  //label format
  .mat-mdc-form-field-label {
    color: palette.$dark-primary-text !important;
    font-weight: 300 !important;
  }

  .invalid {
    color: map.get(palette.$red-palette, "400");
  }

  //tab focus
  &.mat-mdc-focused {
    .mat-mdc-form-field-ripple {
      background-color: palette.$dark-primary-text !important;
      height: 2px !important;
    }

    &.mat-mdc-form-field-invalid .mat-mdc-form-field-ripple {
      background-color: map.get(palette.$red-palette, "400") !important;
      height: 1px !important;
    }
  }

  //icon format
  mat-icon.mat-mdc-icon[mat-mdcSuffix] {
    font-size: 1.5rem !important;
    transform: translateY(0.1rem);
  }

  .caret {
    display: flex;
    align-items: center;
  }

  // input format
  .mat-mdc-input-element {
    caret-color: palette.$dark-primary-text !important;
    font-size: 1.5rem;
    // width: calc(100% - 50px);
    // min-height: 2.7rem;
  }

  //input border bottom format
  .mdc-text-field--filled .mdc-line-ripple::after {
    border-bottom-color: black !important;
  }

  // optional to remove top padding from input field
  &.no-mat-label .mat-mdc-form-field-infix {
    padding-top: 0 !important;
    align-content: flex-end !important;
  }

  .suffix {
    display: flex;
    gap: 0.438rem;
  }

  &.mat-mdc-form-field-appearance-fill .mat-mdc-form-field-flex {
    padding: 0 !important;
    background: transparent !important;
  }

  .mat-mdc-form-field-infix,
  &.mat-mdc-focused .mat-mdc-form-field-infix {
    padding-right: 0;
    transition-duration: 0.2s;
    width: 100%;
  }

  .mat-mdc-form-field-appearance-fill .mat-mdc-form-field-flex {
    background-color: transparent !important;
  }

  .mat-mdc-form-field-appearance-fill .mat-mdc-form-field-subscript-wrapper {
    padding: 0;
  }

  .mat-mdc-form-field-subscript-wrapper {
    margin-top: 0.5rem;
    padding-left: 0;
  }

  mat-mdc-error.mat-mdc-error,
  mat-hint.mat-mdc-hint,
  &.mat-mdc-focused mat-mdc-error.mat-mdc-error,
  &.mat-mdc-focused mat-hint.mat-mdc-hint {
    font-size: 12px;
    font-weight: normal;
  }

  // --- Added from GAIN \/
  &.mat-mdc-form-field-appearance-standard .mat-mdc-form-field-ripple {
    height: 3px;
  }

  &.mat-mdc-form-field-appearance-standard {
    .mat-mdc-form-field-flex {
      padding-top: 0;
    }
  }

  //  Formfield - input
  &.mat-mdc-form-field-can-float.mat-mdc-form-field-should-float
    .mat-mdc-form-field-label,
  &.mat-mdc-form-field-can-float
    .mat-mdc-input-server:focus
    + .mat-mdc-form-field-label-wrapper
    .mat-mdc-form-field-label {
    transform: translateY(-1.25em) scale(1) perspective(100px)
      translateZ(0.001px);
    font-weight: 100;
  }

  span
    .mat-mdc-form-field-label-wrapper
    label.mat-mdc-form-field-label
    mat-mdc-label {
    font-size: 1rem;
  }

  mat-hint.mat-mdc-hint,
  .mat-mdc-form-field-appearance-fill .mat-mdc-form-field-subscript-wrapper {
    padding: 0 !important;
  }

  .mat-mdc-form-field-infix,
  &.mat-mdc-focused .mat-mdc-form-field-infix {
    padding-top: 1rem;
    padding-bottom: 0.5rem;
  }

  .mat-mdc-form-field-underline::before {
    z-index: 999;
    background-color: map.get(palette.$grey-palette, 300) !important;
  }

  .mat-mdc-form-field-underline {
    transform: scale3d(1, 0.5, 1) !important;
  }

  line-height: normal !important;

  &.mat-mdc-invalid .mat-mdc-form-field-label {
    color: variables.$black !important;
  }

  .mat-mdc-form-field-suffix {
    bottom: calc(50% - 0.5rem);
  }

  .mat-mdc-form-field-flex {
    position: relative;
  }

  .mat-mdc-form-field-prefix .mat-mdc-icon,
  .mat-mdc-form-field-suffix .mat-mdc-icon {
    line-height: unset;
    width: auto;
  }

  .mat-mdc-form-field-icon-suffix > .mat-icon {
    padding-bottom: 0.45rem !important;
  }

  .mat-mdc-form-field-icon-suffix:has(mat-spinner) {
    bottom: 0.7rem;
  }

  //  Error icon and caret - remove caret and replace with mat-mdcsuffix
  &.mat-form-field-invalid .mat-mdc-form-field-flex {
    .mat-mdc-form-field-icon-suffix:has(mat-spinner) {
      display: none;
    }

    .mat-mdc-input-element {
      width: calc(100% - 2rem);
    }

    .mat-mdc-form-field-suffix {
      // position: absolute;
      bottom: unset;
      min-height: 2.6rem;
      top: calc(25% + 0.3rem);
      right: 0.5rem;
      animation: caret_animation_go_left 0.3s linear forwards;
    }

    &::after {
      content: "";
      width: 20px;
      height: 21px;
      background: url("data:image/svg+xml,%3Csvg width='20' height='21' viewBox='0 0 20 21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10 0.5C4.48 0.5 0 4.98 0 10.5C0 16.02 4.48 20.5 10 20.5C15.52 20.5 20 16.02 20 10.5C20 4.98 15.52 0.5 10 0.5ZM11 15.5H9V13.5H11V15.5ZM11 11.5H9V5.5H11V11.5Z' fill='%23E12621'/%3E%3C/svg%3E");
      display: block;
      position: absolute;
      right: 0.438rem;
      bottom: 0.7rem;
      border-radius: 50%;
      animation: invalid_error_icon_animation 1s linear;
    }

    &:has(.mat-mdc-form-field-icon-suffix) {
      .mat-mdc-form-field-icon-suffix > .mat-icon {
        padding-bottom: 0.45rem !important;
      }

      &::after {
        right: 2.438rem !important;
        bottom: 0.8rem !important;
      }
    }

    .mat-mdc-select-arrow-wrapper .mat-mdc-select-arrow:after {
      animation: mat-mdc_form_field_carret_animation 0.3s linear forwards;
    }
  }
}

@keyframes mat-mdc_form_field_carret_animation {
  from {
    margin-left: -1.188rem;
  }

  to {
    margin-left: -3rem;
  }
}

@keyframes caret_animation_go_left {
  from {
    padding-right: 0;
  }

  to {
    padding-right: 1.875rem;
  }
}

@keyframes mat-mdc_form_field_carret3_animation {
  from {
    padding-right: 1.875rem;
  }

  to {
    padding-right: 0;
  }
}

@keyframes invalid_error_icon_animation {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.mdc-text-field--filled .mdc-floating-label--float-above {
  transform: translateY(-106%) scale(1) !important;
}

.mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(0, 0, 0, 1) !important;
  font-weight: 100;
}

.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(0, 0, 0, 1) !important;
}

.mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  top: 1.25rem !important;
}

mat-form-field.mat-mdc-form-field.mat-mdc-form-field-type-mat-input
  .mat-mdc-form-field-infix,
mat-form-field.mat-mdc-form-field.mat-mdc-form-field-type-mat-input.mat-mdc-focused
  .mat-mdc-form-field-infix {
  padding-top: 1.75rem !important;
  padding-bottom: 0.5rem !important;
}

.mat-mdc-form-field-hint-wrapper,
.mat-mdc-form-field-error-wrapper {
  padding: 0 !important;
}

.mdc-text-field {
  padding: 0 !important;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: transparent !important;
}

.mat-mdc-form-field-focus-overlay {
  background-color: transparent !important;
}

.mat-mdc-form-field-error {
  color: #e12621 !important;
  font-size: 12px !important;
  font-weight: 400 !important;
}

.mat-mdc-form-field-icon-prefix > .mat-icon,
.mat-mdc-form-field-icon-suffix > .mat-icon {
  padding: 0;
}

mat-form-field.mat-mdc-form-field.mat-mdc-form-field-type-mat-input
  .mat-mdc-input-element {
  width: 100%;
  min-height: 2.5rem;
}

.mat-mdc-form-field-icon-prefix > .mat-icon,
.mat-mdc-form-field-icon-suffix > .mat-icon {
  padding: 0 !important;
}

.mat-mdc-form-field-icon-prefix,
.mat-mdc-form-field-icon-suffix {
  line-height: unset;
}

// Disabled fields
.mdc-text-field--disabled.mdc-text-field--filled {
  background-color: transparent !important;
}

.mdc-text-field--disabled .mdc-line-ripple::before {
  border-bottom-color: rgba(0, 0, 0, 0.42) !important;
}

mat-form-field.mat-mdc-form-field.mat-form-field-disabled
  button.mat-mdc-icon-button
  mat-icon.mat-icon {
  color: rgba(0, 0, 0, 0.38) !important;
}

// TODO: Input form field
mat-form-field.mat-mdc-form-field.mat-mdc-form-field-type-mat-input
  .mat-mdc-form-field-infix,
mat-form-field.mat-mdc-form-field.mat-mdc-form-field-type-mat-input.mat-mdc-focused
  .mat-mdc-form-field-infix {
  padding-bottom: 0.25rem !important;
}
