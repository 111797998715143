@use "sass:map";
@use "../../../utils/variables";
@use "../../../utils/palette";

//unchecked box ,bg,hover,focus
mat-checkbox.mat-mdc-checkbox
  .mdc-checkbox
  .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not(
    [data-indeterminate="true"]
  )
  ~ .mdc-checkbox__background,
mat-checkbox.mat-mdc-checkbox
  .mdc-checkbox:hover
  .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not(
    [data-indeterminate="true"]
  )
  ~ .mdc-checkbox__background,
mat-checkbox.mat-mdc-checkbox
  .mdc-checkbox:not(:disabled):active
  .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not(
    [data-indeterminate="true"]
  )
  ~ .mdc-checkbox__background {
  border-color: variables.$grey-50;
  background-color: transparent;
}

//checkbox tick mark
mat-checkbox.mat-mdc-checkbox
  .mdc-checkbox
  .mdc-checkbox__native-control:enabled
  ~ .mdc-checkbox__background
  .mdc-checkbox__checkmark {
  color: variables.$white;
}

// Override the accent checkbox bg color to match GA typography
//accent box selected,focus
mat-checkbox.mat-mdc-checkbox.mat-accent
  .mdc-checkbox
  .mdc-checkbox__native-control:enabled:checked
  ~ .mdc-checkbox__background,
.mat-mdc-checkbox
  .mdc-checkbox
  .mdc-checkbox__native-control:enabled:indeterminate
  ~ .mdc-checkbox__background,
.mat-mdc-checkbox
  .mdc-checkbox
  .mdc-checkbox__native-control[data-indeterminate="true"]:enabled
  ~ .mdc-checkbox__background,
mat-checkbox.mat-mdc-checkbox.mat-accent
  .mdc-checkbox:not(:disabled):active
  .mdc-checkbox__native-control:enabled:checked
  ~ .mdc-checkbox__background,
.mat-mdc-checkbox
  .mdc-checkbox:not(:disabled):active
  .mdc-checkbox__native-control:enabled:indeterminate
  ~ .mdc-checkbox__background,
.mat-mdc-checkbox
  .mdc-checkbox:not(:disabled):active
  .mdc-checkbox__native-control[data-indeterminate="true"]:enabled
  ~ .mdc-checkbox__background {
  border-color: map.get(palette.$green-palette, "A900");
  background-color: map.get(palette.$green-palette, "A900");
}

// Override the warn checkbox bg color to match GA typography
//warn box checked default ,focus
mat-checkbox.mat-mdc-checkbox.mat-warn
  .mdc-checkbox
  .mdc-checkbox__native-control:enabled:checked
  ~ .mdc-checkbox__background,
.mat-mdc-checkbox
  .mdc-checkbox
  .mdc-checkbox__native-control:enabled:indeterminate
  ~ .mdc-checkbox__background,
.mat-mdc-checkbox
  .mdc-checkbox
  .mdc-checkbox__native-control[data-indeterminate="true"]:enabled
  ~ .mdc-checkbox__background,
mat-checkbox.mat-mdc-checkbox.mat-warn
  .mdc-checkbox:not(:disabled):active
  .mdc-checkbox__native-control:enabled:checked
  ~ .mdc-checkbox__background,
.mat-mdc-checkbox
  .mdc-checkbox:not(:disabled):active
  .mdc-checkbox__native-control:enabled:indeterminate
  ~ .mdc-checkbox__background,
.mat-mdc-checkbox
  .mdc-checkbox:not(:disabled):active
  .mdc-checkbox__native-control[data-indeterminate="true"]:enabled
  ~ .mdc-checkbox__background {
  border-color: map.get(palette.$red-palette, "A900");
  background-color: map.get(palette.$red-palette, "A900");
}

// checkbox label to match GA typography
mat-checkbox.mat-mdc-checkbox {
  label {
    font-size: 1rem;
    line-height: 150%;
  }

  .mdc-checkbox__background {
    border-radius: 3px;
    background: palette.$light-primary-text;
    border: 2px solid variables.$black-50;
  }
}

//unselected checkbox on tab focus
mat-checkbox.mat-mdc-checkbox
  .mdc-checkbox
  .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not(
    [data-indeterminate="true"]
  ):focus-visible
  ~ .mdc-checkbox__background {
  box-shadow: 0 0 0 2px variables.$black;
}

//selected checkbox on tab focus
mat-checkbox.mat-mdc-checkbox.mat-accent
  .mdc-checkbox
  .mdc-checkbox__native-control:enabled:checked:focus-visible
  ~ .mdc-checkbox__background {
  box-shadow: 0 0 0 2px variables.$black;
}

//Indeterminate box format
.mat-mdc-checkbox
  .mdc-checkbox
  .mdc-checkbox__native-control:enabled:checked
  ~ .mdc-checkbox__background,
.mat-mdc-checkbox
  .mdc-checkbox
  .mdc-checkbox__native-control:enabled:indeterminate
  ~ .mdc-checkbox__background,
.mat-mdc-checkbox
  .mdc-checkbox
  .mdc-checkbox__native-control[data-indeterminate="true"]:enabled
  ~ .mdc-checkbox__background {
  border-color: map.get(palette.$green-palette, "A900") !important;
  background-color: map.get(palette.$green-palette, "A900") !important;
}

//Indeterminate checkbox horizontal line
.mat-mdc-checkbox
  .mdc-checkbox
  .mdc-checkbox__native-control:enabled
  ~ .mdc-checkbox__background
  .mdc-checkbox__mixedmark {
  border-color: variables.$white !important;
}

//Indeterminate checkbox tab focus
mat-checkbox.mat-mdc-checkbox
  .mdc-checkbox
  .mdc-checkbox__native-control:enabled:indeterminate:not(
    [data-indeterminate="true"]
  ):focus-visible
  ~ .mdc-checkbox__background {
  box-shadow: 0 0 0 2px variables.$black;
}

//unchecked disabled box
.mat-mdc-checkbox
  .mdc-checkbox
  .mdc-checkbox__native-control[disabled]:not(:checked):not(:indeterminate):not(
    [data-indeterminate="true"]
  )
  ~ .mdc-checkbox__background {
  border-color: variables.$grey-25 !important;
  background-color: transparent !important;
}

//unchecked disabled box label
.mat-mdc-checkbox-disabled > .mdc-form-field > label {
  color: variables.$grey-25;
}

//enabled box label
.mat-mdc-checkbox-checked > .mdc-form-field > label {
  color: variables.$black;
}

//checked disabled
.mat-mdc-checkbox
  .mdc-checkbox
  .mdc-checkbox__native-control[disabled]:checked
  ~ .mdc-checkbox__background,
.mat-mdc-checkbox
  .mdc-checkbox
  .mdc-checkbox__native-control[disabled]:indeterminate
  ~ .mdc-checkbox__background,
.mat-mdc-checkbox
  .mdc-checkbox
  .mdc-checkbox__native-control[data-indeterminate="true"][disabled]
  ~ .mdc-checkbox__background {
  border-color: transparent;
  background-color: variables.$grey-25 !important;
}

.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full {
  background-color: #49a303 !important;
}

// TODO: Checkbox - Mat checkbox color has per design
.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #49a303;
}
