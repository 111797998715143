@use "../../utils/mixins";
@use "../../../sass/utils/variables";

mat-hint.mat-hint {
  color: variables.$black;
}

mat-error.mat-error,
mat-hint.mat-hint {
  @include mixins.hint;
}
